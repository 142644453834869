<template>
	<v-expansion-panel class="background">
		<v-expansion-panel-header expand-icon="fas fa-caret-up">
			<div>
				<span v-if="isChecked" class="padding-right"><i class="fas fa-lg fa-check-circle check-color"></i></span>
				<span :class="[ badBuildingType ? 'grey-out' : '' ]">{{ $t("Building Year") }}</span>
			</div>
			<v-btn :class="modalIconClass" icon raised class="modal-icon" @click.stop="showModal">
				<i class="fas fa-info-circle fa-lg"></i>
			</v-btn>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-form ref="form">
				<v-container>
					<v-row>
						<v-col cols="auto" class="ma-0 pa-0 pt-3">
							<v-checkbox class="ma-0 pa-0"
								v-model="isChecked"
								dense
								:disabled="badBuildingType"
							/>
						</v-col>
						<v-col class="pt-0">
							<v-text-field
								:label="$t('From')"
								hide-details="auto"
								v-model="minValue"
								:rules="minRules"
								:disabled="badBuildingType"
							></v-text-field>
						</v-col>
						<v-col class="pt-0">
							<v-text-field
								:label="$t('To')"
								hide-details="auto"
								v-model="maxValue"
								:rules="maxRules"
								:disabled="badBuildingType"
							></v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>

import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'BuildingYearOption',
	components: {
	},
	data: () => ({
		isChecked: false,
		minValue: null,
		maxValue: null,
		blockUpdate: true,
		minRules: [],
		maxRules: [],
	}),
	watch: {
		isChecked() {
			this.validateRules()
			this.updateStore(this.isChecked)
		},
		minValue() {
			this.validateRules()
			this.validateAndDispatch()
		},
		maxValue() {
			this.validateRules()
			this.validateAndDispatch()
		},
		badBuildingType() {
			if (this.badBuildingType) {
				this.isChecked = false
			}
		},
	},
	computed: {
		buildingTypes() {
			return this.$store.state.targetAudience.buildingTypes.values
		},
		badBuildingType() {
			return (
				this.buildingTypes.includes(3552) || this.buildingTypes.includes(512) || this.buildingTypes.includes(1)
			)
		},
		modalIconClass() {
			if (this.badBuildingType) {
				return 'warning-modal-icon'
			}
			return 'default-modal-icon'
		},
	},
	methods: {
		validateRules() {
			this.$nextTick(() => {
				if (this.$refs.form) {
					this.$refs.form.validate()
				}
			})
		},
		setupVuetifyRules() {
			const rules = [
				value => !!value || this.$t('Required'),
				value => (value || '').length !== 3 || this.$t('Invalid year'),
				value => {
					const pattern = /^[0-9]+$/
					return pattern.test(value) || this.$t('Invalid number')
				},
			]
			this.minRules = [
				...rules,
				value => +value <= +this.maxValue || this.$t('Min larger than max'),
			]
			this.maxRules = [
				...rules,
				value => +value >= +this.minValue || this.$t('Max less than min'),
			]
			this.validateRules()
		},
		showModal() {
			const modalSetup = {
				markdown: 'building-year',
				title: this.$t('Building Year'),
			}
			messageBus.$emit('OPENMODAL', modalSetup)
		},
		removeApply() {
			// If no concent -- clear everything
			this.isChecked = false
		},
		getBuildingYear() {
			const initialValue = this.$store.state.targetAudience.buildingYear.value
			// Without toString Vuetify rule fails
			this.minValue = initialValue.min.toString()
			this.maxValue = initialValue.max.toString()
			this.isChecked = initialValue.apply
		},
		validateAndDispatch() {
			if (this.blockUpdate === true) return
			const pattern = /^[0-9]+$/
			if (!pattern.test(this.minValue) || !pattern.test(this.maxValue)) {
				this.isChecked = false
				this.updateStore(false)
				return
			}
			this.isChecked = true
			this.updateStore(true)
		},
		updateStore(apply) {
			if (this.blockUpdate === true) return
			const buildingYear = { apply, min: this.minValue, max: this.maxValue }
			this.$store.dispatch('saveTargetAudienceSettings', { buildingYear })
		},
	},
	mounted() {
		this.setupVuetifyRules()
		this.getBuildingYear()
		this.$nextTick().then(() => {
			this.blockUpdate = false
		})
		messageBus.$on('REMOVEAPPLY', this.removeApply)
	},
	beforeDestroy() {
		messageBus.$off('REMOVEAPPLY', this.removeApply)
	},
}

</script>

<style lang="scss" scoped>
@import "./SharedExpansionPanelStyles.scss"
</style>