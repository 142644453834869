<template>
  <ViewContainer>
    <div id="map-box" style="position: absolute;" :style="mapPosition"></div>
    <toolbox>
      <adress-search  v-if="workflow !== 'map/brf'" :address="address"></adress-search>
    </toolbox>
  </ViewContainer>
</template>
<script>
import ViewContainer from '../components/ViewContainer.vue'
import AdressSearch from '../components/AdressSearch.vue'
import { Toolbox } from '../components/ToolBox'
import messageBus from '../services/messageBus/MessageBus'
import { DEFAULT_LEFT_POSITION } from '../constants/genericconstants'

export default {
	name: 'Map',
	components: {
		ViewContainer,
		AdressSearch,
		Toolbox,
	},
	data: () => ({
		sidePanelIsOpen: true,
	}),
	computed: {
		address() {
			return this.$store.state.maps.address
		},
		workflow() {
			return this.$store.getters.getWorkflow.join('/')
		},
		mapPosition() {
			let leftPosition = DEFAULT_LEFT_POSITION
			if (!this.sidePanelIsOpen) {
				leftPosition = 0
			}
			const styleObject = {
				left: `${leftPosition}px`,
			}
			return styleObject
		},
	},
	methods: {
		onSidePanelChange(panelState) {
			this.sidePanelIsOpen = panelState.sidePanelIsOpen
		},
	},
	mounted() {
		if (this.$store.state.workflow.length === 0) {
			this.$router.push('/')
		}
		this.$store.dispatch('registerMapsCallback', { mapNodeId: 'map-box', address: this.address })
		this.$store.dispatch('initMaps', { mapNodeId: this.mapNodeId })
		messageBus.$on('ONSIDEPANELCHANGE', this.onSidePanelChange)
	},
	destroyed() {
		this.$store.dispatch('resetMapState')
		messageBus.$off('ONSIDEPANELCHANGE', this.onSidePanelChange)
	},
}
</script>

<style lang="scss" scoped>
@import "./Map.scss";
</style>
