import { getAxiosClient } from '../httpClient'

const cache = {}
let request = false

const serviceURL = process.env.VUE_APP_MAPAPI_URL

function brokersAsArray(brokers) {
	return Object.keys(brokers).map((key) => brokers[key])
}

export function fetchBrokers({ apiToken }) {
	if (cache.brokers) {
		return Promise.resolve(brokersAsArray(cache.brokers))
	}	if (request) {
		return request
	}
	request = getAxiosClient({ apiToken }).get(
		`${serviceURL}/session/brokers`,
	).then(({ data }) => {
		cache.brokers = data.reduce((ack, broker) => {
			Object.assign(ack, { [broker.brokerId]: broker })
			return ack
		}, {})
		return brokersAsArray(cache.brokers)
	})
	return request
}

export function fetchBroker({ apiToken, id }) {
	return fetchBrokers({ apiToken })
		.then(() => cache.brokers[id])
}

export function saveBrokers() {
	return Promise.reject(new Error('Not implemented yet'))
}
