<template>
  <section class="flex-fill-vertical">
    <article class="flex-fill-vertical" v-if="view === 'details'">
      <div class="section flex-fill-vertical">
					<div>
          <v-icon small v-if="working">
            fas fa-sync-alt
            {{ working && "fa-spin" }}
          </v-icon>
					</div>
					<div class="section">
						<div class="button-box">
							<v-btn v-if="isAreaAdmin" small color="secondary-lighten5" @click="createFocusArea" :disabled="!validPolygon">
								<v-icon x-small>fas fa-plus</v-icon>
								{{ $t('PolygonDetails.CreateFocusArea')}}
							</v-btn>
							<v-btn small color="warning" @click="deleteArea">
								<v-icon x-small>fas fa-trash</v-icon>
								{{ $t('PolygonDetails.DeleteAreaPolygon')}}
							</v-btn>
						</div>
					</div>
      </div>
      <div class="tools">
        <address-count
          :variant="variant"
          :addressCount="addressCount"
          :phoneCount="phoneCount"
          layout="row"
        />
        <div class="button-box">
          <v-btn
            :disabled="polygon.addressCount === null || addressCount === 0 || working"
            color="secondary"
            @click="editAddresses"
          >
            <v-icon x-small>fas fa-edit</v-icon>
            {{ $t("PolygonDetails.Edit") }}
          </v-btn>
          <v-btn color="primary" :disabled="working" @click="searchArea">
            <v-icon x-small >fas fa-search</v-icon>
            {{ $t("PolygonDetails.Search") }}
          </v-btn>
        </div>
      </div>
    </article>
    <AddressList
      v-if="view === 'addressList'"
      :addresses="polygon.addresses"
      :excludeList="polygon.excludeList"
      @change="handleExcludeAddresses"
      @cancel="view = 'details'"
    />
  </section>
</template>

<script>
import AddressList from '../../AdressList'
import getCoordinatesFromPolygon from '../../../utils/polygons'
import { addTemporaryFocusArea } from '../../../services/focusAreas'
import AddressCount from '../../Common/AddressCount/AddressCount.vue'

export default {
	name: 'PolygonDetails',
	props: ['polygon'],
	components: {
		AddressList,
		AddressCount,
	},
	data: () => ({
		view: 'details',
		error: false,
		working: false,
	}),
	computed: {
		isAreaAdmin() {
			return this.$store.state.session.focusAreasAdmin
		},
		apiToken() {
			return this.$store.state.session.apiToken
		},
		addressCount() {
			return this.polygon.excludeList.reduce((ack, { count }) => ack - count, this.polygon.addressCount || 0)
		},
		phoneCount() {
			return this.polygon.excludeList.reduce((ack, { tel }) => ack - tel, this.polygon.phoneCount || 0)
		},
		focusAreasActive() {
			return this.$store.state.focusAreas.active
		},
		mapLocationCity() {
			return this.$store.state.maps.location.city
		},
		variant() {
			return this.$store.state.session.variant
		},
		validPolygon() {
			return this.polygon.gPolygon.getPath().length > 2
		},
	},
	methods: {
		deleteArea() {
			this.working = true
			this.$store.dispatch('deletePolygon', { id: this.polygon.id })
				.then(() => {
					this.working = false
					this.$emit('close')
				})
		},
		async createFocusArea() {
			this.working = true
			const areaData = {
				areaName: `${this.$t('FocusAreas.Focus Area')}: ${this.mapLocationCity}`,
				coordinates: getCoordinatesFromPolygon(this.polygon),
			}
			const areaId = await addTemporaryFocusArea({ apiToken: this.apiToken }, areaData)
			await this.$store.dispatch('deletePolygon', { id: this.polygon.id })
			if (!this.focusAreasActive) {
				await this.$store.dispatch('toggleFocusAreas')
			} else {
				this.$store.dispatch('addFocusAreaToMap', { areaId, ...areaData })
			}
			this.$store.dispatch('setFeatureActive', { id: areaId, active: true, edit: true })
			this.working = false
		},
		searchArea() {
			this.working = true
			this.$store.dispatch('searchArea', { id: this.polygon.id }).then(() => {
				this.working = false
			})
		},
		handleExcludeAddresses(excludeList) {
			this.working = true
			this.$store.dispatch('setPolygonAddressesToExclude', { id: this.polygon.id, excludeList }).then(() => {
				this.working = false
			})
		},
		async editAddresses() {
			this.view = 'addressList'
			this.working = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import "./PolygonDetails.scss";
</style>
