<template>
  <Fieldset
    @apply="handleApply"
    :apply="mandatory ? true : apply"
    :legend="$t(legend)"
    :expanded="expanded"
    :expandable="true"
    :disabled="mandatory ? true : disabled"
  >
    <div class="grid">
      <v-checkbox
        dense
        :key="option.value"
        v-for="option in local"
        hide-details="auto"
        v-model="option.selected"
        :id="`${i18nLabels}_${option.value}`"
        @change="onSelect(option.value, $event)"
				:disabled="!apply"
      >
        <template slot="label" >
					<div class="criteria-row">
						<label class="label"> {{ $t(option.label) }} </label>
						<v-btn class="modal-icon" icon raised @click.stop="showModal(option.value)">
							<i class="fas fa-info-circle fa-lg"></i>
						</v-btn>
					</div>
				</template>
      </v-checkbox>
    </div>
  </Fieldset>
</template>
<script>
import Fieldset from './Fieldset.vue'
import messageBus from '../../services/messageBus/MessageBus'
import { BUILDING_TYPE } from '../../constants/genericconstants'

export default {
	name: 'CheckboxSelect',
	components: {
		Fieldset,
	},
	props: {
		legend: {
			type: String,
			required: true,
		},
		i18nLabels: {
			type: String,
			required: true,
		},
		apply: {
			type: Boolean,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		expanded: {
			type: Boolean,
			required: true,
		},
		mandatory: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		local() {
			const options = this.options.map((option) => ({
				...option,
				selected: this.values.includes(option.value),
			}))
			return options
		},
	},
	data: () => ({
		isApplied: false,
		localvalues: [],
		previously: 0,
	}),
	methods: {
		handleApply(apply) {
			this.isApplied = apply
			if (apply) {
				const values = this.local
					.filter((v) => v.selected)
					.map((v) => v.value)
				this.$emit('select', { values, apply: this.isApplied })
			} else {
				this.$emit('select', { values: [], apply: this.isApplied })
			}
		},
		onSelect() {
			const values = this.local
				.filter((v) => v.selected)
				.map((v) => v.value)

			if (!this.isApplied && !this.localvalues.length && values.length) {
				this.isApplied = true
			} else if (this.isApplied && !values.length) {
				this.isApplied = false
			}
			this.localvalues = values.slice()
			this.$emit('select', { values, apply: this.isApplied })
		},
		showModal(buildingTypeCode) {
			const buildingType = this.getBuildingTypeByCode(buildingTypeCode)
			const modalSetup = {
				markdown: `building-type-${buildingType}`,
				title: this.$t(`${buildingType}`),
			}
			messageBus.$emit('OPENMODAL', modalSetup)
		},
		getBuildingTypeByCode(buildingTypeCode) {
			if (buildingTypeCode === 3552) {
				return BUILDING_TYPE.CONDOMINIUM
			} else if (buildingTypeCode === 30) {
				return BUILDING_TYPE.HOUSE
			} else if (buildingTypeCode === 512) {
				return BUILDING_TYPE.TENANCY
			} else {
				return BUILDING_TYPE.OTHER
			}
		},
	},
	mounted() {
		this.isApplied = this.apply
		this.localvalues = this.values.slice()
	},
}
</script>

<style lang="scss" scoped>
@import "./CheckboxSelect.scss";
</style>
