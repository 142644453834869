<template>
  <OptionWrapper>
		<Fieldset
			:legend="$t('Age Group')"
			:expandable="true"
			:expanded="true">
			<div class="age-group">
				<label  class="slider">{{ $t('Min Age' )}}</label>
				<v-slider
					dense
					name='min'
					v-model="min"
					:thumb-size="24"
					:min="age.min"
					:max="age.max"
					@input="handleSelect('min', $event)"
					hide-details="auto"
				></v-slider>
				<div class="label slider">{{ min }}</div>
			</div>
			<div class="age-group">
				<label class="slider">{{ $t('Max Age' )}}</label>
				<v-slider
					dense
					name="max"
					v-model="max"
					:thumb-size="24"
					:min="age.min"
					:max="age.max"
					@input="handleSelect('max', $event)"
					hide-details="auto"
				></v-slider>
				<div class="label slider">{{ max }}</div>
			</div>
		</Fieldset>
    <MarkdownModal
      :markdown="markdown"
      :open="modalOpen"
      @close="modalOpen = false"
      @open="onModalOpen"
    >
      <template slot="title">{{ $t("Age Group") }}</template>
    </MarkdownModal>
  </OptionWrapper>
</template>

<script>
import OptionWrapper from '../OptionWrapper.vue'
import { MarkdownModal } from '../../Common'
import Fieldset from '../../FormComponents/Fieldset.vue'

export default {
	name: 'AgeOption',
	components: {
		MarkdownModal,
		OptionWrapper,
		Fieldset,
	},
	data: () => ({
		modalOpen: false,
		markdown: 'age-groups',
		min: 20,
		max: 100,
		timeout: null,
	}),
	watch: {
		min() {
			if (this.min > this.max) {
				this.$nextTick().then(() => {
					this.min = this.max
				})
			}
		},
		max() {
			if (this.max < this.min) {
				this.$nextTick().then(() => {
					this.max = this.min
				})
			}
		},
	},
	computed: {
		age() {
			return this.$store.state.targetAudience.age
		},
	},
	methods: {
		onModalOpen() {
			this.modalOpen = true
		},
		handleSelect() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				const { min, max } = this
				this.$store.dispatch('saveTargetAudienceSettings', { age: { min, max } })
			}, 250)
		},
	},
	mounted() {
		this.min = this.age.value.min
		this.max = this.age.value.max
	},
}
</script>

<style scoped lang='scss'>
@import "./AgeOption.scss";
</style>