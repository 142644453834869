<template>
	<div class='focusarea-switch-group'>
		<div class='switch-wrap'>
			<v-switch
				small
				:class="{ working }"
				dense
				hide-details=""
				:value="focusAreasActive"
				@change="toggleFocusArea"
			></v-switch>
			<v-icon medium :class="{ working }">
				fas fa-sync-alt fa-spin
			</v-icon>
		</div>
		<span> {{ $t("FocusAreaSwitch.Display Focus Areas") }} </span>
	</div>
</template>

<script>
export default {
	name: 'FocusAreaSwitch',
	computed: {
		focusAreasActive() {
			return this.$store.state.focusAreas.active
		},
	},
	data: () => ({
		working: false,
	}),
	methods: {
		async toggleFocusArea() {
			this.working = true
			this.$emit('working', this.working)
			this.$store.dispatch('setBusy', { busy: true })
			try {
				await this.$store.dispatch('toggleFocusAreas')
			} catch (e) {
				const alertMessage = `Something terrible happened. Please contact support. Error: ${e}`
				// eslint-disable-next-line
				alert(alertMessage)
			} finally {
				this.working = false
				this.$emit('working', this.working)
				this.$store.dispatch('setBusy', { busy: false })
			}
		},
	},
}
</script>

<style scoped lang="scss">
	@import './FocusAreaSwitch.scss';
</style>
