<template>
  <OptionWrapper>
    <checkbox-select
      legend="Housing Type"
      i18nLabels="housingTypes"
      :expanded="true"
      :options="buildingTypes.options"
      :apply="true"
			:mandatory="true"
      :values="values"
      @select="handleSelect"
    />
    <MarkdownModal
      :markdown="markdown"
      :open="modalOpen"
      @close="modalOpen = false"
      @open="onModalOpen"
    >
      <template slot="title">{{ $t("Housing Type") }}</template>
    </MarkdownModal>
  </OptionWrapper>
</template>

<script>
import CheckboxSelect from '../../FormComponents/CheckboxSelect.vue'
import OptionWrapper from '../OptionWrapper.vue'
import { MarkdownModal } from '../../Common'

export default {
	name: 'BuildingTypeOption',
	components: {
		CheckboxSelect,
		MarkdownModal,
		OptionWrapper,
	},
	data: () => ({
		modalOpen: false,
		markdown: 'object-types',
	}),
	watch: {
		values() {
			this.checkForAtLeastOneCheckedBuildingType()
		},
	},
	computed: {
		buildingTypes() {
			return this.$store.state.targetAudience.buildingTypes
		},
		values() {
			return this.buildingTypes?.values || []
		},
	},
	methods: {
		onModalOpen() {
			this.modalOpen = true
		},
		handleSelect(buildingTypes) {
			this.$store.dispatch('saveTargetAudienceSettings', { buildingTypes: buildingTypes.values })
		},
		checkForAtLeastOneCheckedBuildingType() {
			if (this.values.length === 0) {
				this.$nextTick().then(() => {
					this.$store.dispatch('saveTargetAudienceSettings', { buildingTypes: [3552, 30, 512, 1] })
				})
			}
		},
	},
	mounted() {
		this.checkForAtLeastOneCheckedBuildingType()
	},
}
</script>

<style scoped lang='scss'>
@import "./BuildingTypeOption.scss";
</style>