<template>
  <div id="polygon-pane">
		<v-card raised>
			<v-container>
				<div class="pane-header">
					<PanelHeader>
						<h2>{{ $t("PolygonPane.Distribution areas") }}</h2>
					</PanelHeader>
				</div>
				<polygon-list v-if="!focusArea" :polygons="polygons" @add="createPolygon" />
				<area-form v-if="!!focusArea" :focusArea="focusArea" />
			</v-container>
		</v-card>
		<UserHint
			messageType="addressChanged"
			class="address-changed-popover"
			position="right"
			:headerText="$t('Modal.RedirectHeader')"
			:bodyText="$t('Modal.RedirectBody')"
			:defaultVisible="false"
			>
		</UserHint>
  </div>
</template>
<script>
import PolygonList from './PolygonList'
import AreaForm from './AreaForm'
import PanelHeader from '../Common/PanelHeader.vue'
import UserHint from '../Common/UserHint/UserHint.vue'
import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'PolygonPane',
	props: ['isActive'],
	components: {
		PolygonList,
		AreaForm,
		PanelHeader,
		UserHint,
	},
	data: () => ({
		showNameForm: false,
		detailedMode: false,
	}),
	computed: {
		focusArea() {
			const { activeAreaItem } = this.$store.state.focusAreas
			return activeAreaItem
		},
		polygons() {
			return this.$store.state.maps.polygons
		},
		mapNode() {
			return this.$store.state.maps.mapNode
		},
	},
	methods: {
		color2rgb({ r, g, b }) {
			return `rgb(${r},${g},${b})`
		},
		createPolygon() {
			this.$store.dispatch('createPolygon')
				.then((id) => this.setPolygonActive({ id }))
		},
		setPolygonActive({ id }) {
			this.$store.dispatch('setPolygonActive', { id })
		},
		handleMapClick(evt) {
			this.$store.dispatch('addPolygonVertex', evt)
		},
		configure(deregister = false) {
			// Events
			if (this.mapNode && this.isActive) {
				this.$store.dispatch('activateMap')
			} else if ((this.mapNode && !this.isActive) || deregister) {
				this.$store.dispatch('deactivateMap')
			}
		},
		onDetailedMode(mode) {
			this.$emit('detailedMode', mode)
		},
	},
	mounted() {
		this.configure()
		messageBus.$emit('UPDATEZOOMLEVEL')
	},
	watch: {
		mapNode() {
			this.configure()
		},
		isActive() {
			this.configure()
		},
		focusArea(incoming) {
			this.onDetailedMode(!!incoming)
		},
	},
	destroyed() {
		this.configure(true)
	},
}
</script>
<style scoped lang="scss">
@import "./PolygonPanel.scss";
</style>
