<template>
  <v-form name="adress-search" v-on:submit.prevent="onSubmit">
    <v-text-field
      dense
      label="Sökadress"
      v-model="addressInput"
      outlined
      :error-messages="$t(error)"
      hide-details="auto"
    ></v-text-field>
    <v-btn class="mx-2" fab dark small color="primary" @click="onSubmit">
      <v-icon>fas fa-search-location</v-icon>
    </v-btn>
  </v-form>
</template>
<style scoped lang="scss">
@import "./AdressSearch.scss";
</style>
<script>
export default {
	name: 'AdressSearch',
	data: () => ({
		addressInput: '',
	}),
	computed: {
		address() {
			return this.$store.state.maps.location.addressSearch.address
		},
		error() {
			return this.$store.state.maps.location.addressSearch.error
		},
	},
	methods: {
		async onSubmit() {
			const previousAddress = this.address

			// Allow camera move
			// TODO Make into action/dispatch
			this.$store.state.cameraIsPositioned = null

			if (this.addressInput) {
				try {
					await this.$store.dispatch('geoCodeAdress', { address: this.addressInput })
				} catch (e) {
					await this.$store.dispatch('setAlert', {
						timeout: 0,
						raw: {
							title: this.addressInput,
						},
						i18n: {
							message: 'Alerts.Invalid input address',
						},

					})
					await this.$store.dispatch('setSearchAddress', { address: previousAddress, error: '' })
				}
			}
		},
	},
	created() {
		this.addressInput = this.address
	},
	watch: {
		address(newAddress) {
			this.addressInput = newAddress
		},
	},
}
</script>
