<template>
  <div class="polygon-list">
		<modal :open="showPolygonInfo" @close="hidePolygonDetails">
			<template slot='title'>
				{{ showPolygonInfo.name || showPolygonInfo.id }}
			</template>
			<polygon-details
				v-if="showPolygonInfo"
				:polygon="showPolygonInfo"
				@close="hidePolygonDetails"
			/>
		</modal>
    <div>
      <div class="list-head">
        <address-count
					:variant="variant"
					:addressCount="addressCount"
					:phoneCount="phoneCount" />
        <v-btn color="primary" rounded icon small :title="$t('Add area')" @click="$emit('add')">
          <v-icon>fas fa-plus-circle</v-icon>
        </v-btn>
      </div>
			<div class="polygon-list-items">
				<div :key="polygon.id" v-for="polygon in polygons">
					<polygon-list-item :polygon="polygon">
						<ColorMenu
							:color="polygon.color"
							:colors="colors"
							@colorSelect="handleColorSelect({ id: polygon.id, event: $event })"
						/>
						<button class='name' @click="setPolygonActive(polygon)">
							{{
								polygon.name.length > 20
									? `${polygon.name.substring(0, 20)}...`
									: polygon.name
							}}
						</button>
						<div>
							<address-count
								layout="column"
								:variant="variant"
								:phoneCount="getPolygonPhoneCount(polygon)"
								:addressCount="getPolygonAddressCount(polygon)"
							/>
						</div>
						<v-btn depressed small class="mr-2" @click="showPolygonDetails(polygon)" :title="$t('PolygonDetails.Administer')">
							<i class="far fa-tools"></i> / <i class="fad fa-trash"></i>
						</v-btn>
					</polygon-list-item>
					<div :class="[ polygon.gPolygon.getPath().length < 3 === true ? ' ml-2 info-text-red' : 'ml-2 info-text']" v-if="polygon.info !== ''">
						<i class="fas fa-chevron-square-up"></i> {{ polygon.info }}
					</div>
				</div>
			</div>

			<v-row class="mt-1">
				<v-col>
					&nbsp;
				</v-col>
				<v-col>
				<v-btn small :class="[ searchButtonHasPriority === true ? 'primary' : '' ]" @click="updateAllSearches">
					<v-icon class="pr-1 search-btn">fas fa-search</v-icon>
					{{ $t('PolygonDetails.ButtonUpdateSearch') }}
				</v-btn>
				</v-col>
			</v-row>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PolygonListItem from './PolygonListItem.vue'
import ColorMenu from './ColorMenu.vue'
import PolygonDetails from '../PolygonDetails'
import AddressCount from '../../Common/AddressCount'
import Modal from '../../Common/Modal/Modal.vue'
import messageBus from '../../../services/messageBus/MessageBus'

export default {
	components: {
		PolygonListItem,
		ColorMenu,
		PolygonDetails,
		AddressCount,
		Modal,
	},
	name: 'PolygonList',
	props: ['polygons'],
	data: () => ({
		initialized: false,
		working: [],
		showPolygonInfo: false,
		enableSearchButton: false,
		statusTextArray: [],
		searchButtonHasPriority: false,
		highlightSearchButton: false,
		cancelTokenSource: null,
		cancelToken: null,
	}),
	computed: {
		colors() {
			return this.$store.state.maps.colors
		},
		addressCount() {
			const polygonAddressSum = (polygon) => polygon.excludeList.reduce((ack, { count }) => ack - count, polygon.addressCount || 0)
			return this.polygons.reduce((ack, polygon) => ack + polygonAddressSum(polygon), 0)
		},
		phoneCount() {
			const polygonAddressSum = (polygon) => polygon.excludeList.reduce((ack, { tel }) => ack - tel, polygon.phoneCount || 0)
			return this.polygons.reduce((ack, polygon) => ack + polygonAddressSum(polygon), 0)
		},
		includePhoneNumbers() {
			return this.$store.state.targetAudience.includePhoneNumbers.value
		},
		variant() {
			return this.$store.state.session.variant
		},
	},
	updated() {
		if (!this.initialized) {
			this.initialized = true
			this.polygons.forEach(({ id }) => this.searchArea(id))
		}
	},
	methods: {
		setPolygonActive({ id }) {
			this.$store.dispatch('setPolygonActive', { id })
		},
		handleColorSelect({ id, event }) {
			this.$store.dispatch('setPolygonColor', { id, colorIndex: event.index })
		},
		showPolygonDetails(polygon) {
			this.showPolygonInfo = polygon
		},
		hidePolygonDetails() {
			this.showPolygonInfo = false
		},
		onCancelSearch() {
			if (this.cancelTokenSource) {
				this.cancelTokenSource.cancel('Area search canceled by the user')
			}
		},
		searchArea(polygonId) {
			this.cancelTokenSource = axios.CancelToken.source()
			this.cancelToken = this.cancelTokenSource.token
			this.$store.dispatch('setBusy', { busy: true })
			this.working.push(polygonId)
			this.$store.dispatch('searchArea', { id: polygonId, cancelToken: this.cancelToken })
				.then(() => {
					this.working = this.working.filter(id => id !== polygonId)
				})
				.finally(() => {
					this.$store.dispatch('setBusy', { busy: false })
				})
		},
		getPolygonAddressCount(polygon) {
			return polygon.excludeList.reduce(
				(ack, { count }) => ack - count,
				polygon.addressCount || 0,
			)
		},
		getPolygonPhoneCount(polygon) {
			return polygon.excludeList.reduce(
				(phoneCount, { tel }) => (tel ? phoneCount - tel : phoneCount),
				polygon.phoneCount,
			)
		},
		updateAllSearches() {
			this.polygons.forEach((polygon) => {
				this.searchArea(polygon.id)
			})
		},
		setHighlightSearchButton() {
			this.highlightSearchButton = true
			this.checkUpdateStatus()
		},
		checkUpdateStatus() {
			// Note: Could be more compact -- this is for most readability
			// we set polygon-info "raw and brutal" without sending it to the store, as we only use it here
			this.statusTextArray = []

			let newSearchButtonStatusHasPriority = false

			this.polygons.forEach((polygon) => {
				// eslint-disable-next-line
				polygon.info = ''

				if (polygon.hasRun) {
					if (polygon.addressCount > 0 || polygon.phoneCount > 0) {
						// Search is OK and up to date
					} else {
						// Search gave no result
						newSearchButtonStatusHasPriority = true
						// eslint-disable-next-line
						polygon.info = this.$t('PolygonDetails.Info No hits')
					}
				} else if (polygon.gPolygon.getPath().length < 3) {
					// Unused / non finished polyon
					newSearchButtonStatusHasPriority = true
					// eslint-disable-next-line
					polygon.info = this.$t('PolygonDetails.Info Unfinished polygon')
				} else {
					// Current area has changes -- this might occur if item is toggle, e.g. buildingtype or extended criterias
					newSearchButtonStatusHasPriority = true
					// eslint-disable-next-line
					polygon.info = this.$t('PolygonDetails.Info Selection has changed')
				}
			})
			this.searchButtonHasPriority = newSearchButtonStatusHasPriority
		},
	},
	watch: {
		polygons() {
			this.checkUpdateStatus()
		},
	},
	mounted() {
		messageBus.$on('HIGHLIGHTSEARCHBUTTON', this.setHighlightSearchButton)
		messageBus.$on('CANCELSEARCH', this.onCancelSearch)
	},
	beforeDestroy() {
		messageBus.$off('HIGHLIGHTSEARCHBUTTON', this.setHighlightSearchButton)
		messageBus.$off('CANCELSEARCH', this.onCancelSearch)
	},
}
</script>

<style scoped lang="scss">
@import "./PolygonList.scss";
</style>
