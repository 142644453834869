<template>
  <article class="flex-fill-vertical">
    <div class="section flex-fill-vertical">
      <h2>{{ $t("AddressList.Edit Addresslist") }}</h2>
      <div class="flex-fill-vertical">
        <div class="buttons">
					<address-count
						:variant="variant"
						:phoneCount="phoneCount"
						:addressCount="addressCount"
						layout="column" />
          <v-btn small color="secondary" @click="cancel">
            <v-icon x-small>fas fa-ban</v-icon>
            {{ $t("AddressList.Undo") }}
          </v-btn>
          <v-btn small color="primary" @click="handleExcludeAddresses">
            <v-icon x-small>fas fa-save</v-icon>
            {{ $t("AddressList.Save") }}
          </v-btn>
        </div>
        <Ul class="no-margin-top">
          <Li :key="item.address" v-for="item in items">
            <span>{{ item.address }}</span>
						<address-count
							:variant="variant"
							:addressCount="item.count"
							:phoneCount="item.tel" />
            <v-checkbox
              v-model="item.keep"
              hide-details="auto"
              @click="updateCount(item)"
            />
          </Li>
        </Ul>
				<div class="text-align-right">
					<v-btn text class="no-margin" @click="multiCheck(true)" small>{{ $t("AddressList.Button All") }}</v-btn>
					<v-btn text class="no-margin" @click="multiCheck(false)" small>{{ $t("AddressList.Button None") }}</v-btn>
				</div>
      </div>
    </div>
  </article>
</template>

<script>
import { Ul, Li } from '../Common/List'
import AddressCount from '../Common/AddressCount'
import * as variants from '../../constants/variants'

export default {
	name: 'AddressList',
	props: ['addresses', 'excludeList'],
	components: {
		Ul, Li, AddressCount,
	},
	data: (() => ({
		items: [],
		addressCount: 0,
		phoneCount: 0,
		variants,
	})),
	computed: {
		variant() {
			return this.$store.state.session.variant
		},
	},
	methods: {
		cancel() {
			this.$emit('cancel')
		},
		handleExcludeAddresses() {
			const addressesToExclude = this.items
				.filter(entry => !entry.keep)
				.map(({ address, count, tel }) => ({ address, count, tel }))
			this.$emit('change', addressesToExclude)
			this.cancel()
		},
		updateCount({ keep, count, tel }) {
			this.addressCount = keep ? this.addressCount + count : this.addressCount - count
			this.phoneCount = keep ? this.phoneCount + tel : this.phoneCount - tel
		},
		onAddressesUpdated(addresses) {
			const lang = document.documentElement.lang || navigator.language
			this.items = addresses.map((streetAddress) => {
				const keep = !this.excludeList.find(({ address }) => address === streetAddress.address)
				this.addressCount = keep ? this.addressCount + streetAddress.count : this.addressCount
				this.phoneCount = keep ? this.phoneCount + streetAddress.tel : this.phoneCount
				return { ...streetAddress, keep }
			}).sort((a, b) => a.address.localeCompare(b.address, lang))
		},
		multiCheck(checkAll) {
			for (let i = 0; i < this.items.length; i += 1) {
				this.items[i].keep = checkAll
			}
		},
	},
	mounted() {
		this.onAddressesUpdated(this.addresses)
	},
}
</script>

<style scoped lang="scss">
@import "./AddressList.scss";
</style>