<template>
	<div class="wrapper" @click.capture="beforeApply">
		<div :class="{ expanded: isExpanded, header: true }">
			<span class='lead'>
				<v-checkbox dense hide-details :disabled="disabled" v-model='isApplied' @click="toggle" />
				<v-btn :disabled="!expandable && disabled" text x-small @click="toggle">
				<v-icon v-if="expandable" small>fas fa-caret-right</v-icon>
					{{ legend }}
				</v-btn>
			</span>
		</div>
		<div :class="{ main: true, expanded: isExpanded }">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Fieldset',
	props: {
		legend: {
			type: String,
		},
		expanded: {
			type: Boolean,
		},
		apply: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
		expandable: {
			type: Boolean,
			default: true,
		},
		locked: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isExpanded: false,
		isApplied: true,
	}),
	methods: {
		beforeApply(evt) {
			if (this.locked) {
				evt.preventDefault()
				evt.stopPropagation()
				this.$emit('locked')
			}
		},
		toggle() {
			this.isExpanded = !this.isExpanded
		},
	},
	mounted() {
		this.isExpanded = this.expanded
		this.isApplied = this.apply
	},
	updated() {
		if (this.expandable === false) {
			this.isExpanded = false
		}
	},
	watch: {
		apply(val) {
			this.isApplied = val
		},
		isApplied(value) {
			this.$emit('apply', value)
		},
	},
}
</script>

<style scoped lang="scss">
@import "./Fieldset.scss";
</style>