<template>
  <article>
    <div v-if="!editing">
      <h1>{{ form.areaName || focusArea.areaId }}</h1>
      <div class="section">
        <div class="heading">
          <h2>{{$t('FocusAreas.Building types')}}</h2>
          <v-btn icon @click="$store.dispatch('unsetActiveFocusAreaFeature')">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-chip
					class="building-type"
          :key="code"
          v-for="code in form.buildingTypes"
          :color="`${colors[code] || colors[1]}`"
        >
          {{ $t(buildingTypeCodes[code]) || $t("FocusAreas.Other") }}
        </v-chip>
      </div>

      <div class="section">
        <h2>{{$t('FocusAreas.Assigned Broker')}}</h2>
        <div>{{ (broker && broker.name) || $t('FocusAreas.Unassigned') }}</div>
        <div>{{ broker && broker.email }}</div>
      </div>

      <div class="section buttons">
        <v-btn v-if="isfocusAreaAdmin" color="secondary" :disabled="busy" @click="onEdit">
          <v-icon x-small>fas fa-edit</v-icon>
          {{$t('Edit')}}
        </v-btn>
        <v-btn color="primary" :disabled="busy" @click="createPolygon">
          <v-icon x-small>fas fa-check</v-icon>
          {{$t('Apply')}}
        </v-btn>
      </div>
			<span class="area-form-extra-text">{{$t('Focus area building type notification')}}</span>
    </div>
    <div v-if="editing">
      <div class="section">
        <v-text-field :label="$t('FocusAreas.Area name')" v-model="form.areaName" maxlength="100"/>
      </div>

      <div class="section">
        <v-autocomplete
          v-model="form.brokerId"
					:disabled="busy"
          :items="brokers"
          item-text="name"
          item-value="brokerId"
          cache-items
          hide-no-data
          hide-details
          :label="$t('FocusAreas.Assign broker')"
					@change="onBrokerChanged"
        />
      </div>
      <div class="section building-type">
        <building-type-select
					:expanded="true"
					:disabled="busy"
          :options="buildingTypeOptions"
          :values="form.buildingTypes"
					@select="onBuildingOptionsChanged"
        />
      </div>
      <div class="section buttons">
        <v-btn v-if="activeFeatureId > 0" color="secondary" :disabled="busy" @click="onCancelEdit">
          <v-icon x-small>fas fa-ban</v-icon>
          {{$t('Undo')}}
        </v-btn>
        <v-btn color="error" :disabled="busy" @click="onDeleteFocusArea">
          <v-icon x-small>fas fa-trash</v-icon>
          {{$t('Delete')}}
        </v-btn>
        <v-btn color="primary" :disabled="busy || form.buildingTypes.length === 0" @click="onSaveFocusArea">
          <v-icon x-small>fas fa-check</v-icon>
          {{$t('Save')}}
        </v-btn>
      </div>
    </div>
  </article>
</template>

<script>
import BuildingTypeSelect from '../../FormComponents/BuildingTypeSelect.vue'
import { fetchBrokers, fetchBroker } from '../../../services/brokers'
import { saveFocusArea, deleteFocusArea } from '../../../services/focusAreas'

export default {
	name: 'AreaForm',
	props: ['focusArea', 'editMode'],
	components: { BuildingTypeSelect },
	data: () => ({
		busy: false,
		buildingTypeCodes: {
			30: 'FocusAreas.Houses',
			1504: 'FocusAreas.Condos',
			3552: 'FocusAreas.Condos',
			512: 'FocusAreas.Rentals',
			1: 'FocusAreas.Other',
		},
		form: {
			originalId: null,
			brokerId: null,
			areaName: '',
			buildingTypes: [],
		},
		broker: null,
		brokers: [],
	}),
	computed: {
		activeFeatureId() {
			const { activeFeatureId } = this.$store.state.focusAreas
			return activeFeatureId
		},
		apiToken() {
			return this.$store.state.session.apiToken
		},
		colors() {
			return Object.entries(this.$store.state.focusAreas.colors)
				.reduce((ack, [key, { r, g, b }]) => ({
					...ack,
					[key]: `rgb(${r},${g},${b})`,
				}))
		},
		buildingTypeOptions() {
			const { options: buildingTypeOptions } = this.$store.state.targetAudience.buildingTypes
			return buildingTypeOptions.map((option) => ({ ...option, value: `${option.value}` }))
		},
		editing() {
			const editing = this.$store.state.focusAreas.editActiveItem
			if (editing) {
				this.$store.dispatch('setActiveFocusAreaEditable', true)
			} else {
				this.$store.dispatch('setActiveFocusAreaEditable', false)
			}
			return editing
		},
		isfocusAreaAdmin() {
			return this.$store.state.session.focusAreasAdmin
		},
	},
	methods: {
		/**
		 * Resets all the extra options to their default values.
		 * Set "apply" to false to disable the extra options
		 */
		resetExtraOptions() {
			const roomCount = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { roomCount })
			const buildingYear = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { buildingYear })
			const livingArea = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { livingArea })
			const phaseOfLife = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { phaseOfLife })
			const purchasingPower = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { purchasingPower })
			const income = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { income })
			const education = { apply: false }
			this.$store.dispatch('saveTargetAudienceSettings', { education })
		},
		createPolygon() {
			this.resetExtraOptions()
			this.$store.dispatch('updateBuildingTypes', { buildingTypes: this.form.buildingTypes.map(x => +x) })
			this.$store.dispatch('focusArea2Polygon', this.focusArea)
		},
		getCoordinates() {
			const feature = this.$store.state.maps.map.data.getFeatureById(this.activeFeatureId)
			const coordinates = []
			feature.getGeometry().forEachLatLng(({ lat, lng }) => coordinates.push({ lat: lat(), lon: lng() }))
			return coordinates
		},
		onBrokerChanged() {
			this.fetchBroker(this.form.brokerId)
		},
		onBuildingOptionsChanged({ values: buildingTypes }) {
			this.form = {
				...this.form,
				buildingTypes,
			}
		},
		onCancelEdit() {
			this.$store.dispatch('setFeatureActive', { id: this.activeFeatureId, active: true, edit: false })
			this.fetchBroker(this.focusArea.brokerId)
		},
		onEdit() {
			this.$store.dispatch('setFeatureActive', { id: this.activeFeatureId, active: true, edit: true })
		},
		async fetchBrokers() {
			this.busy = true
			this.brokers = await fetchBrokers({ apiToken: this.apiToken })
			this.busy = false
		},
		async fetchBroker(id) {
			this.busy = true
			this.broker = await fetchBroker({ apiToken: this.apiToken, id })
			this.busy = false
		},
		async onSaveFocusArea() {
			try {
				this.busy = true
				// Will get 500 error if brokerId is undefined
				if (this.form.brokerId === undefined) {
					this.form.brokerId = null
				}
				const updatedFocusArea = await saveFocusArea({ apiToken: this.apiToken }, {
					...this.form,
					id: this.focusArea.areaId,
					coordinates: this.getCoordinates(),
				})
				if (this.focusArea.areaId < 1) {
					await this.$store.dispatch('deleteActiveFocusAreaFeature')
					await this.$store.dispatch('addFocusAreaToMap', updatedFocusArea)
				}
				await this.$store.dispatch('setFeatureActive', { id: updatedFocusArea.areaId, active: true, edit: false })
				await this.$store.dispatch('updateFocusAreaFeature', { id: updatedFocusArea.areaId })
			} catch (err) {
				this.$store.dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Could not save changes',
					},
					raw: {
						message: `: ${err.message}`,
					},
				})
			} finally {
				this.busy = false
			}
		},
		async onDeleteFocusArea() {
			this.busy = true
			if (this.focusArea.areaId) {
				try {
					if (this.focusArea.areaId > 0) {
						await deleteFocusArea({ apiToken: this.apiToken }, { id: this.focusArea.areaId })
					}
					this.$store.dispatch('deleteActiveFocusAreaFeature')
				} catch (error) {
					this.$store.dispatch('setAlert', {
						i18n: {
							title: 'Alerts.Alert',
							message: 'Alerts.Generic',
						},
					})
				} finally {
					this.busy = false
				}
			}
		},
	},
	mounted() {
		this.form.brokerId = this.focusArea.brokerId
		this.form.areaName = this.focusArea.areaName
		this.form.originalId = this.focusArea.originalId
		this.form.buildingTypes = this.focusArea.buildingTypes?.split(',').filter(x => !!x) || []
		if (!this.brokers.length) {
			this.fetchBrokers()
		}
		if (this.form.brokerId) {
			this.fetchBroker(this.form.brokerId)
		}
	},
	async beforeDestroy() {
		// Stops the panel from breaking when the focus area panel is active or a focus area is being edited
		this.$store.dispatch('unsetActiveFocusAreaFeature')
	},
	deactivated() {
		console.log('AreaForm Deactivated')
	},
}
</script>

<style lang="scss" scoped>
@import "./AreaForm.scss";
</style>
