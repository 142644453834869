<template>
  <div class="brf-panel">
		<Modal :open='showModal' @close="onCloseModal">
			<template v-if="showErrorDialog || noAddressesFound" slot="title">
				<i class='fas fa-exclamation-triangle' color='	' />
				{{ $t('HousingAssociations.Something went wrong') }}
			</template>
			<div v-if="showErrorDialog">
				{{ $t('HousingAssociations.Invalid response for one or more selected housing association') }}
			</div>
			<div v-if="noAddressesFound">
				{{ $t("HousingAssociations.CouldNotFetchAddresses") }}
			</div>
			<template
				v-if="showAddresslist"
				slot='title'>
				{{ $t('Edit Addresslist') }}
			</template>
			<AddressList
				v-if="showAddresslist"
				:addresses="addresses"
				:excludeList="excludeList"
				@cancel="onCloseModal"
				@change="handleExcludeList"
			/>
		</Modal>
    <v-tabs v-model="tab" background-color="transparent">
      <v-tab>{{ $t("HousingAssociations.Find Housing Association") }}</v-tab>
      <v-tab>{{ $t("HousingAssociations.Saved Searches") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
		<v-card raised>
			<v-container>
      <v-tab-item>
        <BRFSearchForm :disabled="disableFindBrf" @triggerHighlightSearchButton="triggerHighlightSearchButton" />
      </v-tab-item>
      <v-tab-item>
        <BRFSavedSearch :disabled="disableFindBrf" />
      </v-tab-item>
			</v-container>
		</v-card>
    </v-tabs-items>
    <div>
      <panel-header>
        <h2>
          <span>
            {{ $t("HousingAssociations.Selected Housing Cooperatives") }}
          </span>
        </h2>
      </panel-header>
      <div class="results">
        <span :class="{ 'fa-hidden': !searching }">
          <v-icon small
            >fas fa-sync-alt {{ `${searching ? "fa-spin" : ""}` }}</v-icon
          >
        </span>
				<address-count :addressCount="addressCount" :phoneCount="phoneCount" :variant="variant" />
      </div>
      <Ul>
        <Li class="associations-list" :key="item.name" v-for="item in selected">
          <span>{{ item.name }}</span>
          <v-btn icon small @click="handleRemoveBrf(item)">
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </Li>
      </Ul>
    </div>
    <div v-if="!isTelemarketing && enablePhoneNumbers" class="phone-number-input">
      <phone-number-select :value="includePhoneNumbers"/>
    </div>
    <div v-if="showButtons" class="buttons">
      <v-btn
				class="btn-margin-bottom"
				:class="[ highlightSearchButton === true ? 'primary' : '']"
        :disabled="this.searching || !this.selected.length"
        @click="fetchAddresses"
      >
        <v-icon v-if="searching" small color="white">
          fas fa-sync-alt {{ searching && "fa-spin" }}
        </v-icon>
        <v-icon v-if="!searching" small>fas fa-search</v-icon>
        {{ $t("HousingAssociations.ButtonUpdateSearch") }}
      </v-btn>
			<br>
      <v-btn
				small
        color="secondary"
        :disabled="!selected.length"
        @click="handleSaveSearchForm"
      >
        <v-icon small>fas fa-save</v-icon>
        {{ $t("HousingAssociations.Save") }}
      </v-btn>
      <v-btn
				small
        color="secondary"
        :disabled="editDisabled"
        @click="editAddressList"
      >
        <v-icon small>fas fa-edit</v-icon>
        {{ $t("HousingAssociations.Edit") }}
      </v-btn>
    </div>
    <v-form
			@submit.prevent
      v-if="showSaveSearchForm"
      class="save-search-form"
      :disabled="saving"
    >
      <v-text-field
        v-if="showSaveSearchForm === 'default'"
        name="brfName"
        v-model="saveBrfName"
        :label="$t('HousingAssociations.Give a name')"
        @show="console.log(this.$refs)"
      />
      <div
        v-if="showSaveSearchForm === 'default'"
        class="save-search-form-buttons"
      >
        <v-btn
          color="secondary"
          small
          @click="handleCancelSaveSearch"
          :disabled="saving"
        >
          <v-icon small>fas fa-ban</v-icon>
          {{ $t("HousingAssociations.Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          small
          :disabled="!saveBrfName.length || saving"
          @click="handleDoSave"
        >
          <v-icon small>fas fa-save</v-icon>
          {{ $t("HousingAssociations.Save") }}
        </v-btn>
      </div>
      <div v-if="showSaveSearchForm === 'failed'" class="message-box">
        <div class="message">
          <v-icon small color="warning">fas fa-exclamation-triangle</v-icon>
          {{ $t("HousingAssociations.Save failed, try again later") }}
        </div>
        <v-btn color="primary" small @click="handleDoSave">
          <v-icon small>fas fa-check</v-icon>
          {{ $t("OK") }}
        </v-btn>
      </div>
      <div v-if="showSaveSearchForm === 'success'" class="message-box">
        <div class="message">
          {{ $t("HousingAssociations.Successfully saved configuration") }}
        </div>
        <v-btn color="primary" small @click="handleDoSaveConfirm">
          <v-icon small>fas fa-check</v-icon>
          {{ $t("OK") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import messageBus from '../../services/messageBus/MessageBus'
import { PanelHeader, Ul, Li, Modal } from '../Common'
import PhoneNumberSelect from '../TargetingPanel/PhoneNumberSelect.vue'
import BRFSearchForm from './BRFSearchForm.vue'
import BRFSavedSearch from './BRFSavedSearch.vue'
import AddressList from '../AdressList'
import { saveBrfSearch } from '../../services/brf'
import AddressCount from '../Common/AddressCount/AddressCount.vue'
import * as variants from '../../constants/variants'

export default {
	components: { PanelHeader, BRFSavedSearch, BRFSearchForm, Ul, Li, AddressList, PhoneNumberSelect, AddressCount, Modal },
	name: 'BrfPanel',
	data: () => ({
		tab: 0,
		searching: false,
		saving: false,
		dirty: false,
		saveBrfName: '',
		showSaveSearchForm: false,
		showModal: false,
		showErrorDialog: false,
		showAddresslist: false,
		highlightSearchButton: true,
		noAddressesFound: false,
		cancelTokenSource: null,
		cancelToken: null,
	}),
	computed: {
		includePhoneNumbers() {
			return this.$store.state.targetAudience.includePhoneNumbers.value
		},
		apiToken() {
			return this.$store.state.session.apiToken
		},
		selected() {
			return this.$store.getters.getBrfSearchParams.selected
		},
		saved() {
			return this.$store.state.maps.brfSearch.saved
		},
		searchDisabled() {
			return this.selected.length === 0 || this.searching
		},
		showButtons() {
			return !this.showSaveSearchForm
		},
		map() {
			return this.$store.state.maps.map
		},
		addresses() {
			if (this.$store.state.maps.searchResult.addresses === undefined) {
				// If we get zero addresses from one and only one BRF, return empty object so the application won't crash
				this.handleEmptyResult()
				return {}
			}
			return this.$store.state.maps.searchResult.addresses
		},
		excludeList() {
			return this.$store.state.maps.searchResult.excludeList
		},
		count() {
			const { addresses } = this.$store.state.maps.searchResult
			return addresses.reduce((count, adr) => count + adr.count, 0)
		},
		coordinates() {
			return this.$store.state.maps.searchResult.coordinates
		},
		addressCount() {
			return this.$store.getters.getAddressCount
		},
		phoneCount() {
			return this.$store.getters.getPhoneCount
		},
		editDisabled() {
			return this.searching || !this.addresses.length
		},
		disableFindBrf() {
			return this.searching
		},
		variant() {
			return this.$store.state.session.variant
		},
		isTelemarketing() {
			return this.variant === variants.TELEMARKETING
		},
		enablePhoneNumbers() {
			return this.$store.state.session.overrideModules.enablePhoneNumbers
		},

	},
	methods: {
		onCloseModal() {
			this.showModal = false
			this.showErrorDialog = false
			this.showAddresslist = false
		},
		onCancelSearch() {
			if (this.cancelTokenSource) {
				this.cancelTokenSource.cancel('BRF search canceled by the user')
			}
		},
		async fetchAddresses() {
			this.cancelTokenSource = axios.CancelToken.source()
			this.cancelToken = this.cancelTokenSource.token
			this.noAddressesFound = false
			this.$store.dispatch('setBusy', { busy: true })
			this.searching = true
			try {
				await this.$store.dispatch('searchBrfAddresses', this.cancelToken)
			} catch (e) {
				this.showErrorDialog = true
			} finally {
				this.highlightSearchButton = false
				this.searching = false
				this.dirty = false
				this.$store.dispatch('setBusy', { busy: false })
			}
		},
		editAddressList() {
			this.showModal = true
			this.showAddresslist = true
		},
		handleExcludeList(list) {
			this.$store.dispatch('setBrfExcludeList', list)
		},
		async handleRemoveBrf(brf) {
			await this.$store.dispatch('removeBrf', brf)
			this.highlightSearchButton = true
		},
		handleSaveSearchForm() {
			this.showSaveSearchForm = 'default'
		},
		handleCancelSaveSearch() {
			this.showSaveSearchForm = false
		},
		async handleDoSave() {
			try {
				this.saving = true
				const brfIds = this.selected.map(({ id }) => id)
				const brfIdsOrgNumber = this.selected.map(({ orgnr }) => orgnr)
				const brfData = {
					apiToken: this.apiToken,
					name: this.saveBrfName,
					brfIds,
					brfIdsOrgNumber,
				}
				const saved = await saveBrfSearch(brfData)
				await this.$store.dispatch('setSavedBrfs', [...this.saved, saved])
				this.showSaveSearchForm = 'success'
			} catch (e) {
				console.error(e.message)
				this.showSaveSearchForm = 'failed'
			} finally {
				this.saving = false
			}
		},
		handleDoSaveConfirm() {
			this.showSaveSearchForm = false
		},
		triggerHighlightSearchButton() {
			this.highlightSearchButton = true
		},
		handleEmptyResult() {
			// If a single BRF is chosen and returns no addresses, show modal.
			// TODO: this will not trigger if the user does a search with multiple BRF's and at least one of them
			// returns addresses. Example: a BRF search with two BRF's where only one of them returns any addresses.
			// TODO: a BRF search can in some cases return only some of the addresses that is associated with the BRF.
			// What to do if a BRF search returns fewer addresses than it's supposed to?
			this.onNoAddressesFound()
		},
		onNoAddressesFound() {
			this.noAddressesFound = true
			this.showModal = true
		},
	},
	watch: {
		selected() {
			this.dirty = true
			this.$store.dispatch('resetSearchResults')
		},
		addresses(incoming) {
			if (incoming && incoming.length) {
				this.dirty = false
				return this.$store.dispatch('createHeatmap')
			}
			return this.$store.dispatch('clearHeatmap')
		},
		includePhoneNumbers() {
			this.highlightSearchButton = true
		},
	},
	mounted() {
		messageBus.$on('CANCELSEARCH', this.onCancelSearch)
	},
	beforeDestroy() {
		messageBus.$off('CANCELSEARCH', this.onCancelSearch)
	},
}
</script>

<style lang="scss" scoped>
@import "./BrfPanel.scss";
</style>
