<template>
  <div id="circle-pane">
    <div>
      <h1>{{$t('CirclePane.Distribution Circle')}}</h1>
			<v-card raised>
				<v-container>
					<header>
						<address-count
							:variant="variant"
							:addressCount="addressCount"
							:phoneCount="phoneCount" />
					</header>
					<UserHint
						messageType="addressChanged"
						class="address-changed-popover"
						position="right"
						:headerText="$t('Modal.RedirectHeader')"
						:bodyText="$t('Modal.RedirectBody')"
						:defaultVisible="false"
					>
					</UserHint>
					<div class="buttons">
						<div>
							<div class="limit-box">
								<v-text-field
									v-model="resultLimit"
									:disabled="!searchEnabled"
									type="number"
									:label="$t('CirclePane.Limit')"
									class="pl-2"
								>
								</v-text-field>
							</div>
						</div>
						<v-btn
							small
							color="secondary"
							:disabled="!editEnabled"
							@click="editAddressList"
						>
							<v-icon small>fas fa-edit</v-icon>
							{{$t('CirclePane.Edit')}}
						</v-btn>
						<v-btn
							small
							:class="[ highlightSearchButton === true ? 'primary' : '']"
							:disabled="!searchEnabled || !enableSearchButton"
							@click="fetchAddresses"
						>
								<v-icon v-if="working" small color="white">
									fas fa-sync-alt {{ working && "fa-spin" }}
								</v-icon>
								<v-icon v-if="!working" small>fas fa-search</v-icon>
							{{$t('CirclePane.Search')}}
						</v-btn>
					</div>
					<div v-if="!enableSearchButton" class="bad-search-params-message">
						{{ $t('CirclePane.Check search parameters' )}}
					</div>
					<template v-if="checkForMissingCrosshairHasRun">
						<div v-if="!crosshairOnMapExists && this.map" class="circle-search-marker-message">
							<img :src="circleSearchMarker" class="no-crosshair"> {{$t('CirclePane.No Crosshair')}}
						</div>
					</template>
					<div v-if="resultLimit > getMaxAmountForAdr" class="warning-min-max">
						{{ $t('CirclePane.Too Many Addresses') }} ({{ getMaxAmountForAdr }})
					</div>
					<div v-if="getMinAmountForAdr > resultLimit" class="warning-min-max">
						{{ $t('CirclePane.Too Few Addresses') }} ({{ getMinAmountForAdr }})
					</div>
					<div v-if="isTelemarketing()" class="circle-search-marker-message">
						{{ $t('CirclePane.Telemarketing disclaimer') }}
					</div>
				</v-container>
			</v-card>
    </div>
		<modal :open="showModal" @close="showModal=false">
			<template slot='title'>
				{{ $t('Edit Addresslist') }}
			</template>
			<AddressList
				v-if="showModal"
				:addresses="addresses"
				:excludeList="excludeList"
				@cancel="showModal = false"
				@change="handleExcludeList"
			/>
		</modal>
		<UserHint
			class="user-hint"
			position="right"
			:headerText="$t('CirclePane.User hint header')"
			:bodyText="$t('CirclePane.User hint body')"
			:defaultVisible="true"
			>
		</UserHint>
  </div>
</template>
<script>
import axios from 'axios'
import AddressList from '../AdressList'
import AddressCount from '../Common/AddressCount'
import { Modal } from '../Common/Modal'
import UserHint from '../Common/UserHint/UserHint.vue'
import circleSearchMarker36px from '../../assets/circleSearchMarker36px.png'
import * as variants from '../../constants/variants'
import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'CirclePane',
	props: ['isActive'],
	components: {
		AddressList,
		AddressCount,
		Modal,
		UserHint,
	},
	data: (() => ({
		working: false,
		showModal: false,
		activated: false,
		listeners: [],
		resultLimit: 0,
		resultLimitTimeout: null,
		enableSearchButton: true,
		checkForMissingCrosshairHasRun: false,
		highlightSearchButton: true,
		cancelTokenSource: null,
		cancelToken: null,
	})),
	computed: {
		circleSearchMarker() {
			return circleSearchMarker36px
		},
		map() {
			return this.$store.state.maps.map
		},
		heatmap() {
			return this.$store.state.maps.circleSearch.heatmap
		},
		searchEnabled() {
			const { address, error } = this.$store.state.maps.location.addressSearch
			return !error && address && !this.working
		},
		crosshairOnMapExists() {
			return this.$store.state.maps.circleSearch.location !== null
		},
		editEnabled() {
			return this.$store.state.maps.circleSearch.addresses.length > 0 && !this.working
		},
		addresses() {
			return this.$store.state.maps.circleSearch.addresses
		},
		excludeList() {
			return this.$store.state.maps.circleSearch.excludeList
		},
		count() {
			return this.$store.state.maps.circleSearch.count
		},
		coordinates() {
			return this.$store.state.maps.circleSearch.coordinates
		},
		addressCount() {
			return this.$store.getters.getAddressCount
		},
		phoneCount() {
			return this.$store.getters.getPhoneCount
		},
		variant() {
			return this.$store.state.session.variant
		},
		getMaxAmountForAdr() {
			return this.$store.getters.getMaxAmountForAdr
		},
		getMinAmountForAdr() {
			return this.$store.getters.getMinAmountForAdr
		},
	},
	methods: {
		nextStep() {
			this.$router.push({ path: '/distribution' })
		},
		editAddressList() {
			this.showModal = true
		},
		activateClickListener() {
			if (!this.activated && this.map) {
				this.listeners.push(this.map.addListener('click', (evt) => {
					const { latLng: { lat, lng } } = evt
					const position = { lat: lat(), lng: lng() }
					this.$store.dispatch('lookupLocation', position)
						.then(() => {
							this.$store.dispatch('setSearchMarker', position)
						})
						.catch(() => this.$store.dispatch('setAlert', {
							i18n: {
								title: 'Alerts.Alert',
								message: 'Alerts.No valid address found',
							},
						}))
				}))
				this.activated = true
			}
		},
		removeClickListener() {
			const { removeListener } = window.google.maps.event
			this.listeners.forEach(listener => removeListener(listener))
		},
		onCancelSearch() {
			if (this.cancelTokenSource) {
				this.cancelTokenSource.cancel('Circle search canceled by the user')
			}
		},
		async fetchAddresses() {
			this.cancelTokenSource = axios.CancelToken.source()
			this.cancelToken = this.cancelTokenSource.token
			this.$store.dispatch('setBusy', { busy: true })
			this.working = true
			try {
				const { canceled } = await this.$store.dispatch('searchCircle', { resultLimit: +this.resultLimit, cancelToken: this.cancelToken })
				// Do not create heatmap if search was canceled
				// This is to prevent error popup from showing up when user cancels search
				if (!canceled) {
					await this.$store.dispatch('createCircleHeatmap')
				}
			} catch (e) {
				await this.$store.dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Could not perform search',
					},
					raw: {
						message: `: ${e.message}`,
					},
				})
			} finally {
				this.working = false
				await this.$store.dispatch('setBusy', { busy: false })
			}
		},
		handleExcludeList(list) {
			this.$store.dispatch('setCircleExcludeList', list)
		},
		isNormalInteger(str) {
			const n = Math.floor(Number(str))
			return n !== Infinity && String(n) === str && n > 0
		},
		checkForMissingCrosshair() {
			this.checkForMissingCrosshairHasRun = true
		},
		clearCircleHeatmap() {
			this.$store.dispatch('clearCircleHeatmap')
		},
		updateInitialResultLimit() {
			// Without toString Vuetify rule fails
			const resultLimit = this.$store.getters.getTargetAudienceParams.resultLimit
			if (resultLimit > this.getMaxAmountForAdr) {
				this.resultLimit = this.getMaxAmountForAdr.toString()
			} else {
				this.resultLimit = resultLimit.toString()
			}
		},
		isTelemarketing() {
			return this.$store.state.session.variant === variants.TELEMARKETING
		},
	},
	mounted() {
		messageBus.$emit('UPDATEZOOMLEVEL')
		if (!this.activated && this.map) {
			this.activateClickListener()
		}
		this.updateInitialResultLimit()

		// TODO We do not know when the map has loaded, this is just a check after X seconds to show infotext if needed
		setTimeout(this.checkForMissingCrosshair, 5000)
		messageBus.$on('CANCELSEARCH', this.onCancelSearch)
	},
	beforeDestroy() {
		messageBus.$off('CANCELSEARCH', this.onCancelSearch)
	},
	updated() {
		if (!this.activated && this.map) {
			this.activateClickListener()
			this.enableSearchButton = true
		}
	},
	destroyed() {
		this.activated = false
		this.removeClickListener()
	},
	watch: {
		addressCount() {
			this.highlightSearchButton = true
			if (this.addressCount > 0) {
				this.highlightSearchButton = false
			} else {
				this.highlightSearchButton = true
				this.clearCircleHeatmap()
			}
		},
		resultLimit(limit) {
			this.clearCircleHeatmap()
			this.enableSearchButton = true
			if (!this.isNormalInteger(limit) || +limit > this.getMaxAmountForAdr) {
				this.enableSearchButton = false
				return
			}
			clearTimeout(this.resultLimitTimeout)
			this.resultLimitTimeout = setTimeout(() => {
				this.$store.dispatch('saveTargetAudienceSettings', { resultLimit: +limit })
			}, 1000)
		},
		isActive(isActive) {
			if (!this.activated && isActive) {
				this.activateClickListener()
				if (this.heatmap) this.heatmap.setMap(this.map)
			} else {
				if (this.heatmap) this.heatmap.setMap(null)
				this.activated = false
				this.removeClickListener()
			}
		},
	},
}
</script>

<style scoped lang="scss" src="./CirclePane.scss"></style>
