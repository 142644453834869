<template>
  <div>
    <panel-header>
      <h2>{{ $t("HousingAssociations.Saved Searches") }}</h2>
    </panel-header>
    <v-form
			@submit.prevent
			name="savedSearch">
      <v-autocomplete
        v-model="selected"
        :items="items"
        item-text="name"
        item-value="id"
        hide-no-data
        hide-details
        :label="$t('HousingAssociations.Saved Searches')"
        ref="myref"
      />
      <v-btn
        rounded
        icon
				small
				color="error"
        :title="$t('Delete')"
        :disabled="!this.selected"
        @click="onDeleteItem"
      >
        <v-icon>fas fa-trash</v-icon>
      </v-btn>
      <v-btn
        rounded
        icon
				color="primary"
        :title="$t('Add')"
        :disabled="!this.selected"
        @click="onAddItem"
      >
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { PanelHeader } from '../Common'
import { fetchSaved, fetchBrfs, deleteBrfSavedEntry } from '../../services/brf'

export default {
	name: 'BRFSavedSearch',
	components: {
		PanelHeader,
	},
	data: () => ({
		selected: null,
	}),
	computed: {
		apiToken() {
			return this.$store.state.session.apiToken
		},
		items() {
			return this.$store.state.maps.brfSearch.saved
		},
	},
	methods: {
		capitalize(word) {
			return word.toLowerCase().replace(/(?:^|\s)\S/g, firstLetter => firstLetter.toUpperCase()).replace(' I ', ' i ')
		},
		async onAddItem() {
			const savedBrfId = this.selected
			const brfs = await fetchBrfs({ apiToken: this.apiToken, savedBrfId })
			for (let i = 0; i <= brfs.length; i += 1) {
				if (brfs[i]) {
					brfs[i].name = this.capitalize(brfs[i].name)
					if (brfs[i] && brfs[i].city) {
						brfs[i].name = `${brfs[i].name} (${brfs[i].city})`
					}
				}
			}
			brfs.forEach(brf => this.$store.dispatch('addBrf', brf))
			this.selected = null
		},
		async onDeleteItem() {
			try {
				const delId = this.selected
				this.selected = null
				const saved = this.items.filter(({ id }) => id !== delId)
				await deleteBrfSavedEntry({ apiToken: this.apiToken, id: delId })
				await this.$store.dispatch('setSavedBrfs', saved)
				this.$store.dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Successfully deleted entry',
					},
				})
			} catch (e) {
				this.$store.dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Delete Failed!',
					},
				})
			}
		},
	},
	async mounted() {
		const saved = await fetchSaved({ apiToken: this.apiToken })
		await this.$store.dispatch('setSavedBrfs', saved)
	},
}
</script>

<style lang="scss" scoped>
	@import './BRFSavedSearch.scss';
</style>>
