<template>
	<div>
		<MarkdownModal
			:open="showModal"
			type="confirm"
			markdown="extra-variabler"
			@close="onModalResult"
		>
			<template slot="title"> Extra variabler </template>
		</MarkdownModal>
	</div>
</template>

<script>
import { MarkdownModal } from '../../Common'
import messageBus from '../../../services/messageBus/MessageBus'

export default {
	name: 'ExtraOptionsModal',
	components: {
		MarkdownModal,
	},
	data: () => ({
		showModal: false,
	}),
	props: ['showExtraOptionsModal'],
	computed: {
		consentGiven() {
			return this.$store.state.consent.extraVariables
		},
	},
	watch: {
		showExtraOptionsModal() {
			this.showModal = true
		},
	},
	methods: {
		async onModalResult(modalResult) {
			if (modalResult !== 1) {
				await this.$store.dispatch('giveConsent', { extraVariables: false })
				messageBus.$emit('REMOVEAPPLY')
				this.showModal = false
				return
			}
			await this.$store.dispatch('giveConsent', { extraVariables: true })
			this.showModal = false
		},
	},
}
</script>

<style scoped lang="scss">
@import "./ExtraOptionsHeader.scss";
</style>