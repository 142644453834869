<template>
  <div class="edge-align adjusted-position">
    <Fieldset
      :legend="$t('Include Phone Numbers')"
      :expandable="false"
      :apply="localValue"
      :disabled="false"
      :locked="locked"
      @apply="handleSelect"
      @locked="onClick"
    />
    <MarkdownModal
      :open="openModal"
      :type="modalType"
      :markdown="telemarketingMarkdownFile"
      @open="
        () => {
          modalType = 'alert';
          openModal = true;
        }
      "
      @close="setConsentStatus"
    >
      <template slot="title"> Telefonnummer </template>
    </MarkdownModal>
  </div>
</template>
<script>
import Fieldset from '../FormComponents/Fieldset.vue'
import { MarkdownModal } from '../Common/Modal'

export default {
	name: 'IncludePhoneSelect',
	props: ['value'],
	components: {
		Fieldset,
		MarkdownModal,
	},
	data: () => ({
		locked: true,
		openModal: false,
		localValue: false,
		modalType: 'alert',
	}),
	computed: {
		consent() {
			return this.$store.state.consent.phoneNumbers
		},
		telemarketingMarkdownFile() {
			return this.$store.state.session.telemarketingSettings.telemarketingMarkdownFile
		},
	},
	methods: {
		onSelect(value) {
			this.$emit('select', { value })
		},
		onClick() {
			if (this.locked) {
				this.modalType = 'confirm'
				this.openModal = true
			}
		},
		async setConsentStatus(consent) {
			if (consent > 0) {
				this.locked = false
				this.localValue = true
				await this.$store.dispatch('giveConsent', { phoneNumbers: true })
				this.handleSelect(true)
			}
			this.openModal = false
		},
		handleSelect(includePhoneNumbers) {
			this.$store.dispatch('saveTargetAudienceSettings', { includePhoneNumbers })
		},
	},
	mounted() {
		this.localValue = this.value
	},
}
</script>

<style lang="scss" scoped>
@import "./PhoneNumberSelect.scss";
</style>
