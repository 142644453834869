<template>
  <div class="option-wrapper">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
	name: 'OptionWrapper',
}
</script>

<style lang="scss" scoped>
	@import './OptionWrapper.scss'
</style>
