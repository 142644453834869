<template>
  <v-form id="targeting-form" @submit.prevent>
		<PanelHeader v-if="!isTelemarketing && enablePhoneNumbers" class="header-panel">
			<h2>{{ $t("TargetingPanel.Phonenumbers") }}</h2>
			<include-phone-select :value="includePhoneNumbers" />
		</PanelHeader>
		<PanelHeader>
			<h2>{{ $t("TargetingPanel.Selection") }}</h2>
		</PanelHeader>
		<div class="form-controls">
			<BuildingTypeOption />
			<AgeOption />

			<div v-if="enableExtraOptions">
				<hr />
				<PanelHeader>
					<h2><i class="fad fa-bolt eye-catch-icon-color"></i> {{ $t("TargetingPanel.Extended Selection") }}
						<v-btn :class="modalIconClass" icon raised class="modal-icon" @click.stop="showExtraVariableModal">
							<i class="fas fa-info-circle fa-lg"></i>
						</v-btn>
						<div class="info-text-extended-criteria">{{ $t("TargetingPanel.Extended Selection info text") }}</div>
					</h2>
				</PanelHeader>
				<div class="extra-options-message-background">
					<v-chip :class="chipClass" class="extra-options-message-text" label>{{ extraOptionsMessage }}
					</v-chip>
				</div>
				<v-expansion-panels accordion multiple flat focusable class="pa-0 ma-0">
					<RoomCount />
					<BuildingYear />
					<LivingArea />
					<PhaseOfLife />
					<PurchasingPower />
					<Income />
					<Education />
				</v-expansion-panels>
				<ExtraOptionsModal :showExtraOptionsModal="showExtraOptionsModal" />
			</div>
			<MarkdownModal
				v-if="infoModalOpen"
				:markdown="infoModalMarkdown"
				:open="infoModalOpen"
				@close="infoModalOpen = false"
				:renderChange="infoModalMarkdown"
			>
				<template slot='title'>{{ markdownTitle }}</template>
			</MarkdownModal>
		</div>
	</v-form>
</template>
<script>
import IncludePhoneSelect from './PhoneNumberSelect.vue'
import { BuildingTypeOption } from './BuildingTypeOption'
import { AgeOption } from './AgeOption'
import { PanelHeader, MarkdownModal } from '../Common'
import RoomCount from './RoomCountV2.vue'
import LivingArea from './LivingAreaV2.vue'
import BuildingYear from './BuildingYearV2.vue'
import PhaseOfLife from './PhaseOfLifeV2.vue'
import PurchasingPower from './PurchasingPowerV2.vue'
import Income from './IncomeLevelV2.vue'
import Education from './EducationLevelV2.vue'
import ExtraOptionsModal from './ExtraOptionsHeader/ExtraOptionsModal.vue'
import * as variants from '../../constants/variants'
import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'TargetingPanel',
	components: {
		MarkdownModal,
		BuildingTypeOption,
		AgeOption,
		IncludePhoneSelect,
		PanelHeader,
		RoomCount,
		LivingArea,
		BuildingYear,
		PurchasingPower,
		PhaseOfLife,
		Income,
		Education,
		ExtraOptionsModal,
	},
	props: ['isActive'],
	data: () => ({
		showExtraOptions: false,
		maxExtraOptions: 3,
		needConsent: false,
		showExtraOptionsModal: false,
		infoModalOpen: false,
		infoModalMarkdown: '',
		markdownTitle: '',
	}),
	computed: {
		modalIconClass() {
			return 'default-modal-icon'
		},
		includePhoneNumbers() {
			return this.$store.state.targetAudience.includePhoneNumbers.value
		},
		isTelemarketing() {
			return this.$store.state.session.variant === variants.TELEMARKETING
		},
		numberOfExtraOptionsSelected() {
			return this.$store.state.numberOfExtraOptionsSelected
		},
		chipClass() {
			if (this.numberOfExtraOptionsSelected === 0) {
				return 'default'
			}
			if (this.numberOfExtraOptionsSelected > this.maxExtraOptions) {
				return 'red'
			}
			return 'blue lighten-3'
		},
		extraVariablesConsent() {
			return this.$store.state.consent.extraVariables
		},
		consentGiven() {
			return this.$store.state.consent.extraVariables
		},
		extraOptionsMessage() {
			const text1 = this.$t('TargetingPanel.Out of')
			const text2 = this.$t('TargetingPanel.Extra criteria chosen')
			const message = `${this.numberOfExtraOptionsSelected} ${text1} ${this.maxExtraOptions} ${text2}`
			return message
		},
		enableExtraOptions() {
			return this.enableExtraFields && this.extendedCriteriaCost > 0
		},
		enableExtraFields() {
			return this.$store.state.session.overrideModules.enableExtraFields
		},
		enablePhoneNumbers() {
			return this.$store.state.session.overrideModules.enablePhoneNumbers
		},
		extendedCriteriaCost() {
			return this.$store.state.session.extendedCriteriaCost
		},
	},
	watch: {
		async numberOfExtraOptionsSelected() {
			if (this.numberOfExtraOptionsSelected === 0) {
				// If we need to reset this later -- just uncomment line below
				// await this.$store.dispatch('giveConsent', { extraVariables: false })
			} else if (this.consentGiven === false) {
				this.showExtraOptionsModal = !this.showExtraOptionsModal
			}
		},
	},
	methods: {
		showExtraVariableModal() {
			const modalSetup = {
				markdown: 'extra-variabler',
				title: this.$t('Extra variabler'),
			}
			this.openInfoModal(modalSetup)
		},
		openInfoModal(modalSetup) {
			this.infoModalMarkdown = modalSetup.markdown
			this.markdownTitle = modalSetup.title
			this.infoModalOpen = true
		},
		onExtraOptionsConsent(consent) {
			this.extraOptionsConsent = consent
		},
		onShowOptional() {
			if (this.showOptionalInfoStatus) {
				this.showOptional = !this.showOptional
			} else {
				this.optionalInfoType = 'confirm'
				this.showOptionalInfo = true
			}
		},
		setShowOptionalInfoStatus(dialogResult) {
			if (dialogResult > 0) {
				this.showOptionalInfoStatus = 'accepted'
				this.showOptional = true
			}
			this.showOptionalInfo = false
		},
	},
	mounted() {
		this.needConsent = true
		messageBus.$on('OPENMODAL', this.openInfoModal)
	},
	beforeDestroy() {
		messageBus.$off('OPENMODAL', this.openInfoModal)
	},
}
</script>

<style scoped lang="scss">
@import "./TargetingPanel.scss";
</style>
