<template>
	<v-expansion-panel class="background">
		<v-expansion-panel-header expand-icon="fas fa-caret-up">
			<div>
				<span v-if="isChecked" class="padding-right"><i class="fas fa-lg fa-check-circle check-color"></i></span>
				{{ $t('Phase of life') }}
			</div>
			<v-btn :class="modalIconClass" icon raised class="modal-icon" @click.stop="showModal">
				<i class="fas fa-info-circle fa-lg"></i>
			</v-btn>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-container>
				<v-row>
					<v-col class="pt-0">
						<v-checkbox
							class="mr-0 mt-0 sub-checkboxes"
							color="#42A5F5"
							v-for="option in optionSetup"
							dense
							:key="option.value"
							hide-details="auto"
							v-model="optionSelected"
							:label="$t(option.label)"
							:value="option.value"
							@click.stop="updateStatus"
						/>
					</v-col>
					<v-col>
						<div style="font-size: 9pt;">
							<v-btn-toggle rounded>
								<v-btn v-model="dummyButton" small @click="checkAll">{{ $t("Check All") }}</v-btn>
								<v-btn v-model="dummyButton" small @click="checkNone">{{ $t("Check None") }}</v-btn>
							</v-btn-toggle>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>

import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'phaseOfLifeOption',
	components: {
	},
	data: () => ({
		isChecked: false,
		blockUpdate: true,
		optionSetup: [],
		optionSelected: [],
		dummyButton: false,
	}),
	watch: {
		isChecked() {
			if (this.isChecked === false) {
				this.optionSelected = []
			}
			this.updateStore(this.isChecked)
		},
	},
	computed: {
		modalIconClass() {
			return 'default-modal-icon'
		},
	},
	methods: {
		showModal() {
			const modalSetup = {
				markdown: 'phase-of-life',
				title: this.$t('Phase of life'),
			}
			messageBus.$emit('OPENMODAL', modalSetup)
		},
		removeApply() {
			// If no concent -- clear everything
			this.isChecked = false
		},
		checkAll() {
			for (let i = 0; i < this.optionSetup.length; i += 1) {
				this.optionSelected.push(this.optionSetup[i].value)
			}
			this.isChecked = true
			this.dummyButton = true
		},
		checkNone() {
			this.optionSelected = []
			this.isChecked = false
			this.dummyButton = true
		},
		updateStatus() {
			if (this.optionSelected.length === 0) {
				this.isChecked = false
			} else {
				this.isChecked = true
			}
			this.updateStore(this.isChecked)
		},
		getphaseOfLife() {
			this.optionSetup = this.$store.state.targetAudience.phaseOfLife.options
			this.optionSelected = this.$store.state.targetAudience.phaseOfLife.value.values
			this.isChecked = this.$store.state.targetAudience.phaseOfLife.value.apply
		},
		updateStore(apply) {
			if (this.blockUpdate === true) return
			const phaseOfLife = { apply, values: this.optionSelected }
			this.$store.dispatch('saveTargetAudienceSettings', { phaseOfLife })
		},
	},
	mounted() {
		this.getphaseOfLife()
		this.$nextTick().then(() => {
			this.blockUpdate = false
		})
		messageBus.$on('REMOVEAPPLY', this.removeApply)
	},
	beforeDestroy() {
		messageBus.$off('REMOVEAPPLY', this.removeApply)
	},
}

</script>

<style lang="scss" scoped>
@import "./SharedExpansionPanelStyles.scss";
</style>