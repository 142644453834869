<template>
  <div id="zip-pane">
    <div>
      <h1>{{$t("ZipPane.Zip Codes")}}</h1>
			<v-card raised>
				<v-container>
					<header>{{$t("ZipPane.Add Zipcode Range")}}
					<span>
						&nbsp;
					</span>
					<address-count
						:variant="variant"
						:addressCount="addressCount"
						:phoneCount="phoneCount"/>
					</header>
					<UserHint
						messageType="addressChanged"
						class="address-changed-popover"
						position="right"
						:headerText="$t('Modal.RedirectHeader')"
						:bodyText="$t('Modal.RedirectBody')"
						:defaultVisible="true"
						>
					</UserHint>
					<div class="zip-box">
						<div>
							<form class="limit-box">
								<v-text-field
									:class="{
										invalid: zipFromInput.error,
									}"
									:error-messages="zipFromInput.error"
									v-model="zipFromInput.value"
									placeholder="10000"
									type="number"
									:label="$t('ZipPane.From')"
									:title="$t('ZipPane.5 digits required')"
									@blur="validateRange"
									@keyup="validateRange"
								/>
								<v-text-field
									:class="{
										invalid: !zipToInput.error,
									}"
									:error-messages="zipToInput.error"
									v-model="zipToInput.value"
									type="number"
									placeholder="10001"
									:label="$t('ZipPane.To')"
									:title="$t('ZipPane.5 digits required')"
									@blur="validateRange"
									@keyup="validateRange"
								/>
							</form>
						</div>
						<v-btn
							small
							:disabled="!zipInputRangeOK"
							color="primary"
							@click="addZipRange"
						>
							<v-icon small>fas fa-plus-circle</v-icon>
						{{$t("ZipPane.Add")}}
						</v-btn>
					</div>
				</v-container>
			</v-card>
      <div class="zip-list">
        <ul>
          <li class="zip-range" :key="zipRange.join('-')" v-for="(zipRange, index) in zipRanges">
            <span>{{ zipRange[0] }}</span> - <span>{{ zipRange[1] }}</span><span class="range-count" v-if="zipRange.length > 2">(<v-icon small>fas fa-envelope</v-icon> {{ zipRange[2] }}  {{$t('pcs')}}.)</span>
						<span>
							<v-btn x-small icon @click="removeZipRange(index)">
								<v-icon color="var(--v-secondary-darken5">fas fa-trash</v-icon>
							</v-btn>
						</span>
          </li>
					<div class="ml-2 info-text info-text-size" v-if="highlightSearchButton && zipRanges.length > 0">
						<i class="fas fa-chevron-square-up"></i> {{$t("ZipPane.Info Selection has changed")}}
					</div>
					<div class="ml-2 info-text info-text-size" v-else-if="addressCount === 0 && zipRanges.length > 0">
						<i class="fas fa-chevron-square-up"></i> {{$t("ZipPane.Check search parameters")}}
					</div>
        </ul>
        <div class="buttons">
          <v-btn
            color="secondary"
            :disabled="!editEnabled"
            @click="editAddressList"
          >
            <v-icon small>fas fa-edit</v-icon>
            {{$t("ZipPane.Edit")}}
          </v-btn>
          <v-btn
            :class="[ highlightSearchButton === true ? 'primary' : '']"
            :disabled="zipRanges.length < 1 || working"
            @click="fetchAddresses"
          >
            <v-icon v-if="working" color="white">
              fas fa-sync-alt {{ working && "fa-spin" }}
            </v-icon>
            <v-icon v-if="!working" class="pr-2">fas fa-search</v-icon>
            {{$t("ZipPane.ButtonUpdateSearch")}}
          </v-btn>
        </div>
      </div>
    </div>
		<modal :open="showModal" @close="showModal=false">
			<template slot='title'>
					{{ $t('Edit Addresslist') }}
			</template>
			<AddressList
				v-if="showModal"
				:addresses="addresses"
				:excludeList="excludeList"
				@cancel="showModal = false"
				@change="handleExcludeList"
			/>
		</modal>
		<UserHint
			class="user-hint-popover"
			position="right"
			:headerText="$t('ZipPane.User hint header')"
			:bodyText="$t('ZipPane.User hint body')"
			:defaultVisible="false"
			>
		</UserHint>
  </div>
</template>
<script>
import axios from 'axios'
import messageBus from '../../services/messageBus/MessageBus'
import AddressList from '../AdressList'
import AddressCount from '../Common/AddressCount/AddressCount.vue'
import { Modal } from '../Common/Modal'
import UserHint from '../Common/UserHint/UserHint.vue'

export default {
	name: 'ZipPane',
	props: ['isActive'],
	components: {
		AddressList,
		AddressCount,
		Modal,
		UserHint,
	},
	data: (() => ({
		working: false,
		showModal: false,
		listeners: [],
		zipFromInput: {
			value: null,
			error: null,
		},
		zipToInput: {
			value: null,
			error: null,
		},
		zipInputRangeOK: false,
		highlightSearchButton: true,
		initialized: false,
		cancelTokenSource: null,
		cancelToken: null,
	})),
	computed: {
		apiToken() {
			return this.$store.state.session.apiToken
		},
		map() {
			return this.$store.state.maps.map
		},
		heatmap() {
			return this.$store.state.maps.searchResult.heatmap
		},
		streetAddresses() {
			return this.$store.state.maps.searchResult.addresses
		},
		zipRanges() {
			const ranges = this.$store.getters.getZipSearchParams.zipRanges.map((range) => [...range, 0])
			this.streetAddresses.forEach(({ zipCode = 0, count }) => {
				ranges.forEach((range, i) => {
					const [from, to, rangeCount] = range
					ranges[i][2] = zipCode >= from && zipCode <= to ? count + rangeCount : rangeCount
				})
			})
			return ranges
		},
		addresses() {
			return this.$store.state.maps.searchResult.addresses
		},
		excludeList() {
			return this.$store.state.maps.searchResult.excludeList
		},
		coordinates() {
			return this.$store.state.maps.searchResult.coordinates
		},
		addressCount() {
			return this.$store.getters.getAddressCount
		},
		phoneCount() {
			return this.$store.getters.getPhoneCount
		},
		editEnabled() {
			return this.$store.state.maps.searchResult.addresses.length > 0 && !this.working
		},
		variant() {
			return this.$store.state.session.variant
		},
	},
	methods: {
		nextStep() {
			this.$router.push({ path: '/distribution' })
		},
		validateRange() {
			this.zipInputRangeOK = [this.zipFromInput, this.zipToInput].reduce((ack, input) => {
				const valid = /[0-9]{5}/.test(input.value)
				if (valid && ack && input.value.length === 5 && +input.value > 9999) {
					// eslint-disable-next-line
					input.error = null
					return true
				}
				if (input.value) {
					// eslint-disable-next-line
					input.error = this.$t('ZipPane.Not a valid zipcode')
				}
				return false
			}, true)
		},
		async addZipRange() {
			try {
				// Heatmap needs to be cleared when adding a new zipRange. If not, deleting a zipRange and
				// updating the map will still show the heatmap of the deleted zipRange - removeZipRange() does
				// not clear the heatmap as intended. This should propbably be fixed.
				// Clearing the whole heatmap also makes it more consistent with the other search methods
				await this.$store.dispatch('clearHeatmap')
				this.$store.dispatch('addZipRange', [this.zipFromInput.value, this.zipToInput.value])
				this.zipFromInput.value = null
				this.zipToInput.value = null
				this.zipInputRangeOK = false
				this.highlightSearchButton = true
			} catch (e) {
				this.zipFromInput.error = 'Overlapping range'
				this.zipToInput.error = 'Overlapping range'
			}
		},
		async removeZipRange(index) {
			await this.$store.dispatch('removeZipRange', { index })
		},
		editAddressList() {
			this.showModal = true
		},
		onCancelSearch() {
			if (this.cancelTokenSource) {
				this.cancelTokenSource.cancel('Zip search canceled by the user')
			}
		},
		async fetchAddresses() {
			this.cancelTokenSource = axios.CancelToken.source()
			this.cancelToken = this.cancelTokenSource.token
			this.$store.dispatch('setBusy', { busy: true, spinnerSize: '5x' })
			this.working = true
			try {
				const { canceled } = await this.$store.dispatch('searchZipcodes', this.cancelToken)
				this.working = false
				// Do not create heatmap if search was canceled
				// This is to prevent error popup from showing up when user cancels search
				if (!canceled) {
					await this.$store.dispatch('createHeatmap')
				}
			} catch (e) {
				await this.$store.dispatch('setAlert', {
					i18n: {
						title: 'Alerts.Alert',
						message: 'Alerts.Could not perform search',
					},
					raw: {
						message: `: ${e.message}`,
					},
				})
				this.working = false
			} finally {
				await this.$store.dispatch('setBusy', { busy: false })
				this.highlightSearchButton = false
			}
		},
		handleExcludeList(list) {
			this.$store.dispatch('setZipExcludeList', list)
		},
	},
	watch: {
		addressCount() {
			this.$store.dispatch('clearHeatmap')
			this.highlightSearchButton = true
			if (this.addressCount > 0 || this.phoneCount > 0) {
				this.highlightSearchButton = false
			}
		},
		zipRanges() {
			// Highlight the search button if criteria are changed
			this.highlightSearchButton = true
		},
	},
	updated() {
		// Fetch addresses when reopening the selection screen
		if (!this.initialized) {
			this.initialized = true
			if (this.zipRanges.length > 0) {
				this.fetchAddresses()
			}
		}
	},
	mounted() {
		messageBus.$on('CANCELSEARCH', this.onCancelSearch)
	},
	beforeDestroy() {
		messageBus.$off('CANCELSEARCH', this.onCancelSearch)
	},
}
</script>

<style scoped lang="scss">
@import "./ZipPane.scss";
</style>
