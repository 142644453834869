<template>
  <div ref="xyz" :class="{
          'polygon-list-item': true,
          tools: true,
          active: polygon.active,
          [`polygon-background-hint-${polygon.colorIndex}`]: polygon.active,
        }">
		<slot></slot>
		<div v-if="showPolygonTip" class="polygon-tip">
			<v-icon :class="getBackgroundColorClass()">fas fa-lightbulb</v-icon>
			<div>
				{{ $t('PolygonList.Click on the map to begin') }}.
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'PolygonListItem',
	props: ['polygon'],
	methods: {
		getBackgroundColorClass() {
			return `polygon-color-${this.polygon.colorIndex}`
		},
	},
	computed: {
		activeBackgroundColorClass() {
			return `polygon-color${this.polygon.colorIndex}`
		},
		showPolygonTip() {
			return this.polygon.active && !this.polygon.gPolygon.getPath().length
		},
	},
}
</script>

<style scoped lang='scss'>
@import './PolygonListItem.scss';
@import './polygonColors.css';
</style>
