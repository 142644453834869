<template>
  <Fieldset :legend="$t('Housing Type')" :expanded="expanded" :expandable="true">
    <template slot="header">
			<span :key="value" v-for="value in values"> {{ $t(`housingTypes.${value}`) }}</span>
		</template>
    <div class="grid">
      <v-checkbox
        dense
        :key="option.value"
        v-for="option in local"
        hide-details="auto"
        v-model="option.selected"
        :id="`housingType_${option.value}`"
        @change="onSelect"
      >
        <template slot="label">
          <label class="label"> {{ $t(option.label) }} </label>
        </template>
      </v-checkbox>
    </div>
  </Fieldset>
</template>
<script>
import Fieldset from './Fieldset.vue'

export default {
	name: 'buildingTypeSelect',
	components: {
		Fieldset,
	},
	props: {
		options: {
			type: Array,
		},
		values: {
			type: Array,
		},
		expanded: {
			type: Boolean,
		},
	},
	computed: {
		local() {
			return this.options.map((option) => ({
				...option,
				selected: this.values.includes(option.value),
			}))
		},
	},
	methods: {
		onSelect() {
			const values = this.local
				.filter((v) => v.selected)
				.map((v) => v.value)
			this.$emit('select', { values })
		},
	},
}
</script>

<style lang="scss" scoped>
@import "./BuildingTypeSelect.scss";
</style>
