<template>
	<v-card :raised="!disabled" class="next-button-card-override">
		<v-container class="button-padding-margin">
			<div class="button-box">
				<v-btn
					:disabled="disabled"
					color="success"
					type="submit"
					@click="$emit('next')"
				>
					{{ $t("NextButton") }}
					<v-icon :class="[ disabled ? '' : 'bounce' ]" small>fas fa-arrow-alt-circle-right</v-icon>
				</v-btn>
			</div>
		</v-container>
	</v-card>
</template>

<script>
export default {
	name: 'NextButton',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss">
	@import './NextButton.scss';
</style>