<template>
  <div id="toolbox" :class="css">
    <Modal
      type="confirm"
      :open="maxAmountsDialog.open"
      @close="onMaxAmountsDialogClose"
    >
		<template slot="title">
			<div v-if="isTelemarketing">
				{{ $t('Toolbox.Warning: Number of phone numbers') }}
			</div>
			<div v-else>
				{{ $t('Toolbox.Warning: Number of records') }}
			</div>
		</template>
      <template slot="default">
				<div v-if="maxAmountsDialog.exceeded > 0">
					<div v-if="isTelemarketing">
						<p>
							{{ $t('MaxAmounts.Dialog4a') }} <strong>{{ phoneCount }}</strong> {{ $t('MaxAmounts.Dialog1b') }} <strong>{{ maxAmountsDialog.maxAmount }}</strong>
						</p>
						<p>
							({{ $t('MaxAmounts.Dialog4b') }} <strong>{{ maxAmountsDialog.exceeded }}</strong>)
						</p>
					</div>
					<div v-else>
						<p>
							{{ $t('MaxAmounts.Dialog1a') }} <strong>{{ count }}</strong> {{ $t('MaxAmounts.Dialog1b') }} <strong>{{ maxAmountsDialog.maxAmount }}</strong>
						</p>
						<p>
							({{ $t('MaxAmounts.Dialog1c') }} <strong>{{ maxAmountsDialog.exceeded }}</strong>)
						</p>
					</div>
				</div>
				<div v-else>
					<div v-if="isTelemarketing">
						<p>
							{{ $t('MaxAmounts.Dialog4a') }} <strong>{{ phoneCount }}</strong> {{ $t('MaxAmounts.Dialog2b') }} <strong>{{ getMinAmountForAdr }}</strong>
						</p>
						<p>
							({{ $t('MaxAmounts.Dialog2c') }} <strong>{{ maxAmountsDialog.needed }}</strong>)
						</p>
					</div>
					<div v-else>
						<p>
							{{ $t('MaxAmounts.Dialog2a') }} <strong>{{ count }}</strong> {{ $t('MaxAmounts.Dialog2b') }} <strong>{{ getMinAmountForAdr }}</strong>
						</p>
						<p>
							({{ $t('MaxAmounts.Dialog2c') }} <strong>{{ maxAmountsDialog.needed }}</strong>)
						</p>
					</div>
				</div>
				<div class='buttons' :style="{ display: 'flex', justifyContent: 'distance-between' }">
					<v-btn @click="onMaxAmountsDialogClose()" color="primary">
						{{ $t('Toolbox.Cancel') }}
					</v-btn>
				</div>
      </template>
    </Modal>
    <div class="inner">
      <a @click.prevent="toggle">
        <div class="thumbgrip">
          <v-icon v-if="css['off-canvas']" class="bounce">fas fa-angle-right</v-icon>
          <v-icon v-if="!css['off-canvas']">fas fa-angle-left</v-icon>
        </div>
      </a>
      <slot></slot>
      <focus-area-switch v-if="showFocusareaSwitch" />
      <polygon-panel
        v-if="showPolygonPanel"
        isActive="true"
        @detailedMode="onExclusiveMode"
      />
      <circle-pane v-else-if="showCirclePane" />
      <zip-pane v-else-if="showZipPane" />
      <brf-panel v-else-if="showBrfPanel" />
      <targeting-panel v-if="showTargetingPanel" class="overflow" />
      <div class="nextbutton">
        <NextButton :disabled="nextButtonDisabled" @next="nextStep" />
      </div>
    </div>
  </div>
</template>
<script>
import TargetingPanel from '../TargetingPanel'
import PolygonPanel from '../PolygonPanel'
import CirclePane from '../CirclePane'
import ZipPane from '../ZipPane'
import BrfPanel from '../BrfPanel'
import FocusAreaSwitch from '../FormComponents/FocusAreaSwitch.vue'
import NextButton from '../FormComponents/NextButton.vue'
import * as variants from '../../constants/variants'
import { Modal } from '../Common/Modal'
import messageBus from '../../services/messageBus/MessageBus'

export default {
	name: 'Toolbox',
	components: {
		TargetingPanel,
		PolygonPanel,
		CirclePane,
		ZipPane,
		BrfPanel,
		NextButton,
		FocusAreaSwitch,
		Modal,
	},
	data: () => ({
		css: {
			'off-canvas': false,
		},
		exclusiveMode: false,
		maxAmountsDialog: {
			open: false,
			maxAmount: 0,
			exceeded: 0,
			needing: 0,
		},
		sidePanelIsOpen: true,
	}),
	computed: {
		workflow() {
			return this.$store.getters.getWorkflow.join('/')
		},
		count() {
			return this.$store.getters.getAddressCount
		},
		showTargetingPanel() {
			return this.workflow !== 'map/brf' && !this.exclusiveMode
		},
		showFocusareaSwitch() {
			return this.workflow === 'map/areas' && this.$store.state.session.focusAreasEnabled
		},
		showPolygonPanel() {
			return this.workflow === 'map/areas'
		},
		showCirclePane() {
			return this.workflow === 'map/circle'
		},
		showZipPane() {
			return this.workflow === 'map/areacodes'
		},
		showBrfPanel() {
			return this.workflow === 'map/brf'
		},
		variant() {
			return this.$store.state.session.variant
		},
		getMaxAmountForAdr() {
			return this.$store.getters.getMaxAmountForAdr
		},
		getMinAmountForAdr() {
			return this.$store.getters.getMinAmountForAdr
		},
		numberOfExtraOptionsSelected() {
			return this.$store.state.numberOfExtraOptionsSelected
		},
		nextButtonDisabled() {
			return this.count === 0 || this.$store.state.numberOfExtraOptionsSelected > 3 || this.getIncompletePolys === true
		},
		getIncompletePolys() {
			// Only return check if polygonsearch, i.e ignore if circle search, zip-search etc
			if (this.workflow !== 'map/areas') {
				return false
			}
			return this.$store.getters.getIncompletePolys
		},
		phoneCount() {
			return this.$store.getters.getPhoneCount
		},
		isTelemarketing() {
			return this.$store.state.session.variant === variants.TELEMARKETING
		},
	},
	methods: {
		async nextStep(override) {
			if (override) {
				this.$router.push({ path: '/distribution' })
				return
			}

			const minAmount = this.getMinAmountForAdr
			const maxAmount = this.getMaxAmountForAdr

			// Show modal if address count is too low
			if (!this.isTelemarketing && (this.count < minAmount || this.count > maxAmount)) {
				this.maxAmountsDialog = {
					...this.maxAmountsDialog,
					open: true,
					maxAmount,
					exceeded: this.count - maxAmount,
					needed: minAmount - this.count,
				}
				return
			}

			// Show modal if telemarketing and phoneCount is too low
			if (this.isTelemarketing && (this.phoneCount < minAmount || this.phoneCount > maxAmount)) {
				this.maxAmountsDialog = {
					...this.maxAmountsDialog,
					open: true,
					maxAmount,
					exceeded: this.phoneCount - maxAmount,
					needed: minAmount - this.phoneCount,
				}
				return
			}

			if (this.variant === variants.TELEMARKETING) {
				this.$store.dispatch('setBusy', { busy: true, spinnerSize: '10x' })
				await this.$store.dispatch('submitTelemarketingSelection')
				this.$store.dispatch('setBusy', { busy: false })
			} else {
				this.$router.push({ path: '/distribution' })
			}
		},
		toggle() {
			this.sidePanelIsOpen = !this.sidePanelIsOpen
			const payLoad = { sidePanelIsOpen: this.sidePanelIsOpen }
			messageBus.$emit('ONSIDEPANELCHANGE', payLoad)
			this.css['off-canvas'] = !this.css['off-canvas']
		},
		onExclusiveMode(active) {
			this.exclusiveMode = active
		},
		onMaxAmountsDialogClose() {
			this.maxAmountsDialog = {
				...this.maxAmountsDialog,
				open: false			}
		},
	},
	watch: {
		nextButtonDisabled() {
			messageBus.$emit('DISABLEDISTRIBUTION', this.nextButtonDisabled)
		},
	},
	mounted() {
		messageBus.$emit('DISABLEDISTRIBUTION', this.nextButtonDisabled)
	},
}
</script>

<style lang="scss" scoped>
@import "./Toolbox.scss";
</style>
