<template>
<div v-if="isVisible" @click="isVisible = false">
	<div class="popover">
		<header v-if="headerText !== ''" class="popover-header popover-text">
			<div class="header-margin">
				{{ headerText }}
			</div>
			<div class="close-button">
				<i class="fal fa-window-close"></i>
			</div>
		</header>
	<div>
		<div :class="trianglePosition">
			<div :class="innerTrianglePosition"></div>
		</div>
		<div v-if="headerText" class="popover-body popover-text keep-formatting">
			{{ bodyText }}
		</div>
		<div v-else class="popover-body-no-header popover-text keep-formatting">
			{{ bodyText }}
		</div>
	</div>
</div>
</div>
</template>

<script>
import messageBus from '../../../services/messageBus/MessageBus'

export default {
	data: (() => ({
		isVisible: true,
	})),
	props: ['headerText', 'bodyText', 'position', 'messageType', 'defaultVisible'],
	computed: {
		trianglePosition() {
			switch (this.position) {
			case 'right':
				return 'triangle-left'
			case 'top':
				return 'triangle-bottom'
			case 'bottom':
				return 'triangle-top'
			default:
				return 'triangle-right'
			}
		},
		innerTrianglePosition() {
			switch (this.position) {
			case 'right':
				return 'inner-triangle-left'
			case 'top':
				return 'inner-triangle-bottom'
			case 'bottom':
				return 'inner-triangle-top'
			default:
				return 'inner-triangle-right'
			}
		},
	},
	methods: {
		displayAddressChanged() {
			if (this.messageType === 'addressChanged') {
				this.isVisible = true
			}
		},
		appIsClicked() {
			this.isVisible = false
		},
	},
	mounted() {
		this.isVisible = true
		if (this.defaultVisible === false) {
			this.isVisible = false
		}
		messageBus.$on('DISPLAYADDRESSCHANGED', this.displayAddressChanged)
		messageBus.$on('APPISCLICKED', this.appIsClicked)
		messageBus.$on('SHOWUSERHINT', this.showUserHint)
	},
	beforeDestroy() {
		messageBus.$off('DISPLAYADDRESSCHANGED', this.displayAddressChanged)
		messageBus.$off('APPISCLICKED', this.appIsClicked)
		messageBus.$off('SHOWUSERHINT', this.showUserHint)
	},
}
</script>

<style scoped lang="scss">
@import './UserHint.scss';
</style>
