<template>
  <div>
    <panel-header>
      <h2>{{ $t("HousingAssociations.Find Housing Association Header") }}</h2>
    </panel-header>
    <v-form @submit.prevent name="search" :disabled="disabled">
      <v-autocomplete
        ref="myref"
        item-text="name"
        item-value="id"
        cache-items
        hide-no-data
        hide-details
        v-model="selected"
        :items="items"
        :label="$t('HousingAssociations.Find Housing Association')"
        @keyup="doSearch"
				@change="onAddItem"
      />
      <v-btn
        rounded
        icon
				color="primary"
        :title="$t('Add')"
        :disabled="!selected || disabled"
        @click="onAddItem"
      >
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </v-form>
		<UserHint
			class="user-hint"
			position="right"
			:headerText="$t('HousingAssociations.User hint header')"
			:bodyText="$t('HousingAssociations.User hint body')"
			:defaultVisible="true"
			>
		</UserHint>
  </div>
</template>

<script>
import { brfSearch } from '../../services/brf'
import { PanelHeader } from '../Common'
import UserHint from '../Common/UserHint/UserHint.vue'

export default {
	name: 'BRFSearchForm',
	components: {
		PanelHeader,
		UserHint,
	},
	props: {
		disabled: {
			type: Boolean,
		},
	},
	data: () => ({
		selected: null,
		items: [],
		allItems: [],
		searches: [],
		selectionSet: new Set(),
		timeout: false,
		toggleTriggerHighlightSearchButton: true,
	}),
	computed: {
		apiToken() {
			return this.$store.state.session.apiToken
		},
	},
	methods: {
		capitalize(word) {
			return word.toLowerCase().replace(/(?:^|\s)\S/g, firstLetter => firstLetter.toUpperCase()).replace(' I ', ' i ')
		},
		async search(queryText) {
			this.items = []

			if (queryText.length < 3) return
			if (this.searches.includes(queryText)) return
			if (this.timeout) clearTimeout(this.timeout)
			// TODO Can we get rid of timeout?
			this.timeout = setTimeout(async () => {
				this.searches.push(queryText)
				this.items = await brfSearch({ apiToken: this.apiToken, text: queryText })

				for (let i = 0; i <= this.items.length; i += 1) {
					if (this.items[i]) {
						this.items[i].name = this.capitalize(this.items[i].name)
						if (this.items[i] && this.items[i].city) {
							this.items[i].name = `${this.items[i].name} (${this.items[i].city})`
						}
					}
				}
				this.items.forEach(i => {
					if (!this.allItems.includes(i)) {
						this.allItems.push(i)
					}
				})
			}, 300)
		},
		doSearch(evt) {
			if (evt.key.length > 1) return
			// TODO: Fix this ugly hack and find a better way to retrieve current input text
			const queryText = `${this.$refs.myref.$el.querySelector('input[type=text]').value}`
			this.search(queryText)
		},
		onAddItem() {
			const brf = this.allItems.find(item => +item.id === +this.selected)
			if (brf) {
				this.toggleTriggerHighlightSearchButton = !this.toggleTriggerHighlightSearchButton
				this.$emit('triggerHighlightSearchButton', this.toggleTriggerHighlightSearchButton)
				this.$store.dispatch('addBrf', brf)
				this.selected = []
			}
		},
	},
}
</script>

<style lang="scss" scoped>
	@import './BRFSearchForm.scss';
</style>
