<template>
	<v-menu top>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				small
				:color="color"
				icon
				v-bind="attrs"
				v-on="on"
			>
				<v-icon small>fas fa-circle</v-icon>
			</v-btn>
		</template>

		<v-list >
			<v-list-item class="color-menu-item" dense v-for="(color, index) in colors" :key="index" @click="handleSelect(index)">
				<v-icon small dense :color="color2rgb(color)">fas fa-circle</v-icon>
			</v-list-item>
		</v-list>
	</v-menu>

</template>

<script>
export default {
	name: 'ColorMenu',
	props: ['color', 'colors'],
	methods: {
		color2rgb({ r, g, b }) {
			return 	`rgb(${r},${g},${b})`
		},
		handleSelect(index) {
			this.$emit('colorSelect', { index })
		},
	},
}
</script>

<style scoped lang="scss">
@import './ColorMenu.scss';
</style>
