import { render, staticRenderFns } from "./IncomeLevelV2.vue?vue&type=template&id=522fd6f8&scoped=true"
import script from "./IncomeLevelV2.vue?vue&type=script&lang=js"
export * from "./IncomeLevelV2.vue?vue&type=script&lang=js"
import style0 from "./IncomeLevelV2.vue?vue&type=style&index=0&id=522fd6f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522fd6f8",
  null
  
)

export default component.exports